/* line 7, ../assets/sass/style_custom.scss */
.grunt_test_a4s_zp7_v1 {
  color: blue;
}

/* line 14, ../assets/sass/style_custom.scss */
.zp-page-wrapper {
  overflow: hidden;
  position: relative;
}

/* line 31, ../assets/sass/style_custom.scss */
h1, h2 {
  color: gray;
}

/* Sticky header Beackground images*/
/* line 38, ../assets/sass/style_custom.scss */
.cd-hero {
  background-size: cover !important;
}
/* line 40, ../assets/sass/style_custom.scss */
.cd-hero .cd-hero-content {
  vertical-align: bottom;
}
/* line 50, ../assets/sass/style_custom.scss */
.sticky-header.small-hero .cd-hero {
  background: white;
}
/* line 61, ../assets/sass/style_custom.scss */
.shop-page.z01 .sticky-header.small-hero .cd-hero img.bg {
  width: 100%;
}

@media (min-width: 992px) {
  /* line 70, ../assets/sass/style_custom.scss */
  #navbar-collapse-1 {
    float: right;
  }
}
/* line 78, ../assets/sass/style_custom.scss */
#navbar-collapse-1 .usermenu-email {
  padding: 3px 20px 7px;
  color: lightgray;
  cursor: default;
}

/* line 87, ../assets/sass/style_custom.scss */
.small-hero .header-inner {
  position: relative;
  padding-right: 0;
  /*
  @media (max-width: 992px) {
      padding: 0;
  }
  */
}
/* line 95, ../assets/sass/style_custom.scss */
.small-hero .header-inner .zp-logo-top {
  background: #6699FF;
  width: 255px;
  height: 60px;
  left: 0;
  position: relative;
  transform: none;
  padding: 5px;
  float: left;
}
/* line 105, ../assets/sass/style_custom.scss */
.small-hero .header-inner .zp-logo-top img {
  height: 50px;
  margin: auto;
}
/* line 110, ../assets/sass/style_custom.scss */
.small-hero .header-inner .cd-primary-nav-2.small {
  float: right;
  /*
  button {
      @media (max-width: 992px) {
          display: block !important;
      }
  }
  */
}
/* line 113, ../assets/sass/style_custom.scss */
.small-hero .header-inner .cd-primary-nav-2.small #navbar-collapse-1 {
  max-height: 300px;
  /*
  //display: none;
  &.collapse.in {
      //display: block;
      
  }
  @media (min-width: 992px) {
      display: block !important;
  }
  */
}
/* line 117, ../assets/sass/style_custom.scss */
.small-hero .header-inner .cd-primary-nav-2.small #navbar-collapse-1 .usermenu-email {
  padding: 3px 20px 7px;
  color: lightgray;
  cursor: default;
}
@media (min-width: 992px) {
  /* line 125, ../assets/sass/style_custom.scss */
  .small-hero .header-inner .cd-primary-nav-2.small #navbar-collapse-1 .main-menu li.last {
    position: relative;
  }
  /* line 127, ../assets/sass/style_custom.scss */
  .small-hero .header-inner .cd-primary-nav-2.small #navbar-collapse-1 .main-menu li.last .dropdown-menu {
    right: 0;
  }
  /* line 131, ../assets/sass/style_custom.scss */
  .small-hero .header-inner .cd-primary-nav-2.small #navbar-collapse-1 .main-menu li.first {
    position: relative;
  }
  /* line 133, ../assets/sass/style_custom.scss */
  .small-hero .header-inner .cd-primary-nav-2.small #navbar-collapse-1 .main-menu li.first .dropdown-menu {
    left: 0;
    right: initial;
  }
}
/* line 161, ../assets/sass/style_custom.scss */
.small-hero .cd-hero {
  margin-top: 0;
}
@media (min-width: 992px) {
  /* line 163, ../assets/sass/style_custom.scss */
  .small-hero .cd-hero .cd-hero-content {
    background: url(/f/zp-design/back/back_11.png) no-repeat center right;
    background-size: cover;
  }
}
/* line 171, ../assets/sass/style_custom.scss */
.small-hero .cd-hero .cd-hero-content .header-banner {
  text-align: left;
  background: white url(/f/zp-design/back/back_11.png) no-repeat center right 0px;
  background-size: cover;
  position: relative;
  height: 160px;
  padding-left: 270px;
}
@media (max-width: 1124px) {
  /* line 171, ../assets/sass/style_custom.scss */
  .small-hero .cd-hero .cd-hero-content .header-banner {
    overflow: hidden;
    padding-left: 150px;
  }
}
@media (max-width: 992px) {
  /* line 171, ../assets/sass/style_custom.scss */
  .small-hero .cd-hero .cd-hero-content .header-banner {
    padding-left: 0;
    background: white !important;
  }
}
/* line 193, ../assets/sass/style_custom.scss */
.small-hero .cd-hero .cd-hero-content .header-banner img {
  height: 100%;
  width: auto !important;
  float: left;
}
@media (max-width: 700px) {
  /* line 199, ../assets/sass/style_custom.scss */
  .small-hero .cd-hero .cd-hero-content .header-banner img.banner {
    display: none;
  }
  /* line 202, ../assets/sass/style_custom.scss */
  .small-hero .cd-hero .cd-hero-content .header-banner img.banner-mobile {
    display: block !important;
  }
}
/* line 216, ../assets/sass/style_custom.scss */
.small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block {
  float: right;
  /*
  .shop-page & {
      float: left;
  }
  */
}
/* line 224, ../assets/sass/style_custom.scss */
.small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block .shop-title a {
  color: #6699FF;
  white-space: nowrap;
}
@media (max-width: 480px) {
  /* line 231, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block {
    max-width: 60%;
  }
  /* line 233, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block.default-ribbon-lets-go {
    max-width: initial;
  }
  /* line 236, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block.default-ribbon::after {
    content: "...";
    color: #6699FF;
    float: right;
    position: relative;
    top: -19px;
    right: -15px;
    font-weight: bold;
  }
  /* line 245, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block .shop-title {
    overflow: hidden;
  }
}
@media (max-width: 992px) {
  /* line 251, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header #main-menu-logo-block {
    float: left;
  }
  /* line 254, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header button {
    background: white;
  }
  /* line 257, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header button.collapsed {
    background: transparent;
  }
  /* line 264, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header button.blink-bg {
    animation: blink_shop_button_1 1s;
    animation-iteration-count: 7;
    background-color: white;
  }
  /* line 271, ../assets/sass/style_custom.scss */
  .z01 .small-hero .cd-secondary-nav .navbar-header button.blink-bg {
    border: 0.7px solid #02884d;
    animation: blink_shop_button_1_z01 1s;
    animation-iteration-count: 7;
  }
  /* line 277, ../assets/sass/style_custom.scss */
  .z02 .small-hero .cd-secondary-nav .navbar-header button.blink-bg {
    border: 0.7px solid #b4d200;
    animation: blink_shop_button_1_z02 1s;
    animation-iteration-count: 7;
  }
  /* line 283, ../assets/sass/style_custom.scss */
  .z03 .small-hero .cd-secondary-nav .navbar-header button.blink-bg {
    border: 0.7px solid #f30145;
    animation: blink_shop_button_1_z03 1s;
    animation-iteration-count: 7;
  }
  /* line 291, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header button.blink-bg .icon-bar {
    animation: blink_shop_button_2 1s;
    animation-iteration-count: 7;
  }
  /* line 294, ../assets/sass/style_custom.scss */
  .z01 .small-hero .cd-secondary-nav .navbar-header button.blink-bg .icon-bar {
    background-color: #02884d;
    animation: blink_shop_button_2_z01 1s;
    animation-iteration-count: 7;
  }
  /* line 300, ../assets/sass/style_custom.scss */
  .z02 .small-hero .cd-secondary-nav .navbar-header button.blink-bg .icon-bar {
    background-color: #b4d200;
    animation: blink_shop_button_2_z02 1s;
    animation-iteration-count: 7;
  }
  /* line 306, ../assets/sass/style_custom.scss */
  .z03 .small-hero .cd-secondary-nav .navbar-header button.blink-bg .icon-bar {
    background-color: #f30145;
    animation: blink_shop_button_2_z03 1s;
    animation-iteration-count: 7;
  }
  @keyframes blink_shop_button_1 {
    0% {
      background-color: #6699FF;
      border-color: white;
    }
    100% {
      background-color: white;
      border-color: #6699FF;
    }
  }
  @keyframes blink_shop_button_2 {
    0% {
      background-color: white;
    }
    100% {
      background-color: #6699FF;
    }
  }
  @keyframes blink_shop_button_1_z01 {
    0% {
      background-color: #02884d;
      border-color: white;
    }
    100% {
      background-color: white;
      border-color: #02884d;
    }
  }
  @keyframes blink_shop_button_2_z01 {
    0% {
      background-color: white;
    }
    100% {
      background-color: #02884d;
    }
  }
  @keyframes blink_shop_button_1_z02 {
    0% {
      background-color: #b4d200;
      border-color: white;
    }
    100% {
      background-color: white;
      border-color: #b4d200;
    }
  }
  @keyframes blink_shop_button_2_z02 {
    0% {
      background-color: white;
    }
    100% {
      background-color: #b4d200;
    }
  }
  @keyframes blink_shop_button_1_z03 {
    0% {
      background-color: #f30145;
      border-color: white;
    }
    100% {
      background-color: white;
      border-color: #f30145;
    }
  }
  @keyframes blink_shop_button_2_z03 {
    0% {
      background-color: white;
    }
    100% {
      background-color: #f30145;
    }
  }
}
@media (min-width: 992px) {
  /* line 215, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header {
    position: relative;
    float: left;
  }
  /* line 358, ../assets/sass/style_custom.scss */
  .shop-page .small-hero .cd-secondary-nav .navbar-header {
    width: 255px;
    text-align: right;
  }
  /* line 365, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header a {
    padding: 4px 0 !important;
  }
  /* line 367, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav .navbar-header a img {
    max-width: 100%;
    float: right;
    max-height: 100%;
    height: auto;
  }
}
/* line 377, ../assets/sass/style_custom.scss */
.small-hero .cd-secondary-nav #navbar-collapse-2 {
  overflow: hidden !important;
}
@media (min-width: 992px) {
  /* line 377, ../assets/sass/style_custom.scss */
  .small-hero .cd-secondary-nav #navbar-collapse-2 {
    display: block !important;
  }
}

/* Upper menu */
@media (min-width: 992px) {
  /* line 392, ../assets/sass/style_custom.scss */
  .main-menu #account-menu-button-link {
    background: url(/sites/all/themes/zp_bootstrap/img/icons/profile-icon-gray-30.png) no-repeat 14px 10px;
    padding-left: 54px;
    /* Green user icon for logged in users */
  }
  /* line 397, ../assets/sass/style_custom.scss */
  .logged-in .main-menu #account-menu-button-link {
    background: url(/sites/all/themes/zp_bootstrap/img/icons/profile-icon-blue-tint-30.png) no-repeat 14px 10px;
  }
  /* line 400, ../assets/sass/style_custom.scss */
  .logged-in.guest .main-menu #account-menu-button-link {
    /* Guests user icon */
    background: url(/sites/all/themes/zp_bootstrap/img/icons/profile-icon-orange-30.png) no-repeat 14px 10px;
  }

  /* line 407, ../assets/sass/style_custom.scss */
  .open > #account-menu-button-link,
  .open > #account-menu-button-link:hover,
  .open > #account-menu-button-link:focus {
    background: url(/sites/all/themes/zp_bootstrap/img/icons/profile-icon-white-30.png) no-repeat 14px 10px;
  }
}
/* Fix for span instead of link (no url parent menu item) */
/* line 420, ../assets/sass/style_custom.scss */
.navbar-nav > li > span.nolink {
  display: block;
  padding: 15px;
  cursor: pointer;
  color: #6699FF;
}
/* line 428, ../assets/sass/style_custom.scss */
.navbar-default .navbar-nav > .open > span.nolink, .navbar-default .navbar-nav > .open > span.nolink:hover, .navbar-default .navbar-nav > .open > span.nolink:focus {
  background-color: #2C5FC7 !important;
  color: white;
}
/* line 434, ../assets/sass/style_custom.scss */
.navbar-default .navbar-nav > .open ul li a {
  color: #6699FF;
}
/* line 437, ../assets/sass/style_custom.scss */
.navbar-default .navbar-nav > .open ul li.active a {
  background: white;
}
/* line 439, ../assets/sass/style_custom.scss */
.navbar-default .navbar-nav > .open ul li.active a:hover {
  background: #f5f5f5;
}

/* line 447, ../assets/sass/style_custom.scss */
ul.nav.navbar-nav.main-menu {
  /* Fix for a Upper menu link without children */
  /* Remove active link coloring for Upper menu */
}
/* line 449, ../assets/sass/style_custom.scss */
ul.nav.navbar-nav.main-menu > li > a {
  color: #6699FF !important;
  top: -2px;
}
/* line 454, ../assets/sass/style_custom.scss */
ul.nav.navbar-nav.main-menu a.active {
  background: initial;
  color: #6699FF;
}

/* line 461, ../assets/sass/style_custom.scss */
.cd-auto-hide-header {
  height: 60px !important;
  /* Top Sticky for Front page */
}
/* line 465, ../assets/sass/style_custom.scss */
.front .cd-auto-hide-header {
  height: 0px !important;
}
/* line 470, ../assets/sass/style_custom.scss */
.cd-auto-hide-header nav.cd-primary-nav-2, .cd-auto-hide-header nav.cd-primary-nav-2 .navbar-default.yamm {
  background: transparent;
  border: none;
}
@media (max-width: 768px) {
  /* line 469, ../assets/sass/style_custom.scss */
  .cd-auto-hide-header nav.cd-primary-nav-2 {
    background-color: #daf9f7;
  }
  /* line 478, ../assets/sass/style_custom.scss */
  .cd-auto-hide-header nav.cd-primary-nav-2 .navbar.navbar-default.yamm {
    background: transparent;
  }
}

/* line 486, ../assets/sass/style_custom.scss */
.navbar-default {
  /* AJAX Cart*/
}
/* line 488, ../assets/sass/style_custom.scss */
.navbar-default.yamm {
  margin: auto;
  border-radius: 0;
  /*
  @media (min-width: 768px) {
    width: 700px;
  }
  @media (min-width: 992px) {
    width: 900px;
  }
  @media (min-width: 1200px) {
    width: 1070px;
  }
  */
}
/* line 505, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp {
  position: absolute;
  right: 0;
  top: 7px;
}
/* line 510, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp .cart-image {
  margin-right: 2px;
}
/* line 512, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp .cart-image .cart-icon-teaser {
  width: 50px;
  height: 40px;
  background: url(/sites/all/themes/zp_bootstrap/img/icons/cart-icon-teaser_a.png) no-repeat 7px 3px transparent;
}
/* line 516, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp .cart-image .cart-icon-teaser:hover {
  background: url(/sites/all/themes/zp_bootstrap/img/icons/cart-icon-teaser_b.png) no-repeat 7px 3px transparent;
}
/* line 522, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp #cart-amount-n-total {
  font-size: 12px;
  overflow: hidden;
}
/* line 526, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp #cart-amount-n-total.zp-cart-empty {
  line-height: 0.6;
  padding-top: 3px;
}
/* line 530, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp #cart-amount-n-total.zp-cart-empty .cart-product-quantity {
  margin-top: 10px;
}
/* line 534, ../assets/sass/style_custom.scss */
.navbar-default .cart-zp #cart-amount-n-total .cart-product-total {
  float: none;
}
@media (max-width: 768px) {
  /* line 505, ../assets/sass/style_custom.scss */
  .navbar-default .cart-zp {
    top: 0;
  }
  /* line 541, ../assets/sass/style_custom.scss */
  .navbar-default .cart-zp .cart-product-quantity {
    position: relative;
    margin-left: 11px;
    top: -2px;
    font-size: 9px;
  }
  /* line 547, ../assets/sass/style_custom.scss */
  .navbar-default .cart-zp .zp-cart-empty .cart-product-quantity {
    margin-top: 0 !important;
  }
  /* line 550, ../assets/sass/style_custom.scss */
  .navbar-default .cart-zp .cart-image {
    float: inherit;
    margin-right: 10px;
  }
  /* line 554, ../assets/sass/style_custom.scss */
  .navbar-default .cart-zp .cart-product-total {
    display: none;
  }
}

/* line 563, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav, nav.cd-secondary-nav .navbar-default.yamm {
  border: none;
  background: #f9f9f9;
}
/* line 567, ../assets/sass/style_custom.scss */
.shop-page nav.cd-secondary-nav, .shop-page nav.cd-secondary-nav .navbar-default.yamm {
  background: #eff8ff;
}
/* line 573, ../assets/sass/style_custom.scss */
.shop-page nav.cd-secondary-nav .navbar-default.yamm {
  background: white url(/f/zp-design/back/menu_ribbon_back_11.jpg) no-repeat center right;
  background-size: cover;
}
@media (max-width: 992px) {
  /* line 578, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 {
    background: #f3f3f3;
    border-bottom: 3px solid lightgray;
    font-size: 20px;
  }
  /* line 589, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 li.yamm-fw > a {
    border-bottom: 1px dotted lightgray;
  }
  /* line 594, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .shop-delivery-button {
    color: #6699FF;
  }
  /* line 596, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .shop-delivery-button:hover {
    color: #2C5FC7;
  }
}
@media (max-width: 768px) {
  /* line 603, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 li a {
    border-bottom: 1px dotted lightgray;
  }
  /* line 607, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .level-2 a {
    font-size: 17px;
  }
}
/* line 614, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .shop-delivery-button span {
  display: block;
  padding: 15px;
  cursor: help;
}
/* line 619, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .shop-delivery-button span.disabled {
  background: lightgray;
}
@media (min-width: 992px) {
  /* line 578, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 {
    /*
    .mega > li.open {
        border-top: 1px solid gray;
        > a {
            background: white;
            font-weight: bold;
            color: gray;
        }
    }
    */
  }
  /* line 629, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .mega > li.open {
    border-top: 1px solid gray;
  }
  /* line 632, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .mega > li.open > a {
    background: white;
    font-weight: bold;
    color: gray;
  }
  /* line 639, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .mega .shop-delivery-button {
    padding: 7px;
  }
  /* line 641, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .mega .shop-delivery-button span {
    padding: 8px;
    background: #2C5FC7;
    color: white;
    border-radius: 5px;
  }
  /* line 647, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .mega .shop-delivery-button span.disabled {
    background: gray;
  }
  /* line 650, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-default.yamm #navbar-collapse-2 .mega .shop-delivery-button span:hover {
    background: #6699FF;
  }
}
/* line 672, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav.fixed {
  /* fixes a bug where nav and subnab move with a slight delay */
  top: 60px !important;
}
/* line 675, ../assets/sass/style_custom.scss */
.front nav.cd-secondary-nav.fixed {
  top: 0px !important;
  /* fixes a bug where nav and subnab move with a slight delay */
  padding-top: 10px;
  height: 70px;
}
/* line 682, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav.slide-up {
  transform: translateY(-60px) !important;
}
/* line 684, ../assets/sass/style_custom.scss */
.front nav.cd-secondary-nav.slide-up {
  transform: translateY(0px) !important;
}
/* line 689, ../assets/sass/style_custom.scss */
.front nav.cd-secondary-nav {
  /*
  .navbar-toggle {
      @media (max-width: 768px) {
          float: right;
      }
  }
  */
}
/* line 690, ../assets/sass/style_custom.scss */
.front nav.cd-secondary-nav, .front nav.cd-secondary-nav .navbar-default.yamm {
  background: white;
}
/* line 703, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav .navbar-toggle {
  /* Mobile menu - Collapsed */
  float: left;
  margin-left: 10px;
}
@media (max-width: 768px) {
  /* line 708, ../assets/sass/style_custom.scss */
  .front nav.cd-secondary-nav .navbar-toggle {
    float: right;
  }
}
@media (min-width: 992px) {
  /* line 703, ../assets/sass/style_custom.scss */
  nav.cd-secondary-nav .navbar-toggle {
    display: none !important;
  }
}
/* line 717, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav #main-menu-logo-block .navbar-brand {
  padding: 15px 15px 15px 0;
}
/* line 719, ../assets/sass/style_custom.scss */
nav.cd-secondary-nav #main-menu-logo-block .navbar-brand.with-logo {
  padding: 9px 0;
}

/* Header Logo */
/* line 730, ../assets/sass/style_custom.scss */
.zp-logo-top.logo img {
  height: 50px;
}
/* line 734, ../assets/sass/style_custom.scss */
.front .zp-logo-top.logo {
  display: none;
}
/* line 737, ../assets/sass/style_custom.scss */
.front .fixed .zp-logo-top.logo {
  display: block;
  float: left;
}
@media (max-width: 768px) {
  /* line 737, ../assets/sass/style_custom.scss */
  .front .fixed .zp-logo-top.logo {
    margin: 5px 0 0 20px;
  }
}

/* Mobile menu - Collapsed */
/* line 751, ../assets/sass/style_custom.scss */
ul.level-3 {
  background: white !important;
}
/* line 753, ../assets/sass/style_custom.scss */
ul.level-3 li {
  margin-left: 45px;
  list-style: disc;
}
/* line 756, ../assets/sass/style_custom.scss */
ul.level-3 li a {
  padding: 5px !important;
}

/* line 762, ../assets/sass/style_custom.scss */
.sticky-header.small-hero .cd-hero {
  height: 220px !important;
}

/* Mega menu - Wide */
/* line 768, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega {
  display: none;
}
/* line 771, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul a {
  white-space: normal;
}
/* line 773, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul a.expand-no-click {
  /* Make expanded item (with subitems) not clicables/marked in Mega menu */
  background: none;
  border: none;
}
/* line 779, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul.level-2 {
  column-count: 4;
  overflow-y: auto;
  padding: 20px 10px;
  margin-left: 15px;
}
/* line 781, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul.level-2.items-count-3 {
  column-count: 3;
}
/* line 784, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul.level-2.items-count-2 {
  column-count: 2;
}
/* line 787, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul.level-2.items-count-1 {
  column-count: 1;
}
/* line 795, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega ul.level-2.no-third-level {
  column-rule-style: dotted;
  column-rule-color: #ccc;
}
/* line 801, ../assets/sass/style_custom.scss */
ul.navbar-nav.mega li.level-3 a {
  margin-left: 15px;
  border-left: 1px dotted lightgray;
  font-size: 14px;
  color: gray;
}
/* line 809, ../assets/sass/style_custom.scss */
ul.navbar-nav.mobile {
  display: block;
  /* Make mobile Catalog menu y-scrollable */
  overflow-y: auto;
  max-height: 300px;
}

@media (min-width: 768px) {
  /* line 819, ../assets/sass/style_custom.scss */
  ul.navbar-nav.mega {
    display: block;
  }
  /* line 822, ../assets/sass/style_custom.scss */
  ul.navbar-nav.mobile {
    display: none;
  }
}
@media (min-width: 992px) {
  /* line 832, ../assets/sass/style_custom.scss */
  #navbar-collapse-1 span.u-menu {
    float: left;
  }
  /* line 835, ../assets/sass/style_custom.scss */
  #navbar-collapse-1 span.m-menu {
    float: right;
  }
}
/* line 841, ../assets/sass/style_custom.scss */
#navbar-collapse-1 ul.nav.navbar-nav {
  float: right;
}
@media (max-width: 992px) {
  /* line 841, ../assets/sass/style_custom.scss */
  #navbar-collapse-1 ul.nav.navbar-nav {
    width: 100%;
    text-align: right;
  }
  /* line 848, ../assets/sass/style_custom.scss */
  #navbar-collapse-1 ul.nav.navbar-nav li {
    text-align: right;
  }
}

/* No shop section Main menu ribbon */
/* line 856, ../assets/sass/style_custom.scss */
.default-menu-ribbon a {
  color: lightgray;
  text-decoration: initial;
  font-size: 30px;
  font-style: italic;
  font-family: monospace;
  font-weight: 900;
}
/* line 864, ../assets/sass/style_custom.scss */
.default-menu-ribbon a.default::before {
  content: ">>> ";
}
/* line 868, ../assets/sass/style_custom.scss */
.default-menu-ribbon .back {
  font-size: 15px;
  line-height: 1;
  margin-top: 5px;
  text-align: right;
  font-style: italic;
  color: gray;
}
/* line 876, ../assets/sass/style_custom.scss */
.default-menu-ribbon .shop-title a {
  font-size: 22px;
  line-height: 1;
}
@media (max-width: 768px) {
  /* line 855, ../assets/sass/style_custom.scss */
  .default-menu-ribbon {
    /* Let's go shopping '*/
  }
  /* line 881, ../assets/sass/style_custom.scss */
  .default-menu-ribbon .back {
    text-align: left;
  }
  /* line 885, ../assets/sass/style_custom.scss */
  .default-menu-ribbon a.default {
    font-size: 20px;
    padding: 10px 0 0 15px;
    display: block;
  }
}

/* line 893, ../assets/sass/style_custom.scss */
#navbar-collapse-2 .choose-another-shop {
  margin: 22px 0 0 0;
}
/* line 895, ../assets/sass/style_custom.scss */
#navbar-collapse-2 .choose-another-shop .delimiter {
  padding: 0 10px 0 0;
  color: gray;
  font-style: italic;
}
/* line 900, ../assets/sass/style_custom.scss */
#navbar-collapse-2 .choose-another-shop a {
  color: #2C5FC7;
  font-style: italic;
  text-decoration: none;
}
@media (max-width: 768px) {
  /* line 893, ../assets/sass/style_custom.scss */
  #navbar-collapse-2 .choose-another-shop {
    margin: 15px 0 15px 65px;
  }
  /* line 907, ../assets/sass/style_custom.scss */
  #navbar-collapse-2 .choose-another-shop .delimiter {
    display: none;
  }
  /* line 910, ../assets/sass/style_custom.scss */
  #navbar-collapse-2 .choose-another-shop a {
    color: gray;
    font-size: 20px;
  }
}

/* Language select */
/* line 920, ../assets/sass/style_custom.scss */
form#lang_dropdown_form_language {
  position: absolute;
  top: 8px;
  right: 450px;
}
/* line 925, ../assets/sass/style_custom.scss */
form#lang_dropdown_form_language select {
  background: transparent;
  color: #6699FF;
  border: 1px solid #6699FF;
}
/* line 929, ../assets/sass/style_custom.scss */
form#lang_dropdown_form_language select option {
  color: #6699FF;
}
@media (max-width: 992px) {
  /* line 920, ../assets/sass/style_custom.scss */
  form#lang_dropdown_form_language {
    right: 73px;
  }
  /* line 936, ../assets/sass/style_custom.scss */
  form#lang_dropdown_form_language select {
    color: white;
    border-color: white;
  }
}
/* line 942, ../assets/sass/style_custom.scss */
form#lang_dropdown_form_language select {
  padding-right: 0;
  width: 67px;
}

/* Mobile version of Header */
@media (max-width: 992px) {
  /* line 952, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner {
    padding-left: 0;
  }
  /* line 954, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small {
    width: 100%;
    /* background: transparent; */
    overflow: hidden;
    z-index: 1;
    position: relative;
    background: #6699FF;
  }
  /* line 961, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar {
    width: 100%;
    min-height: 60px;
    border-bottom: 3px solid white;
    /*
    #navbar-collapse-1 {
        overflow: hidden;
        color: white;
        margin-top: 9px;
        background: rgb(235, 132, 166);
        border-bottom: 3px solid rgb(204, 51, 102);
        font-size: 17px;
        padding-right: 20px;
        line-height: 0;
        text-align: right;
    }
    */
  }
  /* line 965, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar .navbar-header {
    min-height: 60px;
  }
  /* line 968, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar .navbar-header button span {
    background: white;
  }
  /* line 971, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar .navbar-header button:hover, .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar .navbar-header button:focus {
    background: white;
  }
  /* line 973, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar .navbar-header button:hover span, .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar .navbar-header button:focus span {
    background: #6699FF;
  }
  /* line 994, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 {
    padding: 0;
    background: white;
    border-bottom: 2px solid #6699FF;
    font-size: 17px;
  }
  /* line 999, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul {
    margin: 0;
  }
  /* line 1001, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li {
    border-bottom: 1px solid #6699FF;
  }
  /* line 1006, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li a, .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li span {
    color: #6699FF !important;
  }
  /* line 1010, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li.open {
    background: #d3d3d333;
  }
  /* line 1011, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li.open span.dropdown-toggle {
    color: white !important;
  }
  /* line 1015, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li.open .usermenu-email {
    padding: 5px 15px 10px 25px;
  }
  /* line 1017, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul > li.open .usermenu-email span {
    color: gray !important;
  }
  /* line 1023, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul li li {
    border-bottom: 1px solid #d3d3d366;
  }
  /* line 1025, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul li li.last {
    border-bottom: none;
  }
  /* line 1028, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul li li:hover {
    background: #d3d3d342;
  }
  /* line 1031, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .cd-primary-nav-2.small .navbar #navbar-collapse-1 ul li li a {
    color: #2C5FC7 !important;
    padding: 10px 25px 10px 0;
    font-size: 17px;
  }
  /* line 1044, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .zp-logo-top {
    position: absolute;
    top: 0;
    z-index: 1;
    text-align: left;
    padding-left: 15px;
    margin-left: 15px;
    background: transparent;
  }
  /* line 1052, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .header-inner .zp-logo-top img {
    margin: 0;
  }
  /* line 1059, ../assets/sass/style_custom.scss */
  .sticky-header.small-hero .cd-hero .cd-hero-content .shop-header {
    padding: 0;
  }
}
/* Footer */
/* line 1071, ../assets/sass/style_custom.scss */
#footer-wrapper {
  width: 100%;
}
/* line 1074, ../assets/sass/style_custom.scss */
#footer-wrapper footer {
  min-height: 200px;
}
/* line 1078, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right {
  padding: 20px 0 0 0;
  color: white;
  width: 75%;
  float: right;
  text-align: left;
}
/* line 1084, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info {
  background: #6699FF;
  padding: 20px 20px 20px 10px;
}
/* line 1086, ../assets/sass/style_custom.scss */
.front #footer-wrapper footer .zp-footer-right .info {
  background: #cc3366;
}
/* line 1091, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .logo {
  margin-bottom: 20px;
}
/* line 1093, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .logo img {
  height: 70px;
}
/* line 1096, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .logo img.on-front {
  display: none;
}
/* line 1098, ../assets/sass/style_custom.scss */
.front #footer-wrapper footer .zp-footer-right .info .logo img.on-front {
  display: block;
  margin: auto;
}
/* line 1104, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .logo img.not-on-front {
  display: block;
}
/* line 1106, ../assets/sass/style_custom.scss */
.front #footer-wrapper footer .zp-footer-right .info .logo img.not-on-front {
  display: none;
}
@media (max-width: 768px) {
  /* line 1104, ../assets/sass/style_custom.scss */
  #footer-wrapper footer .zp-footer-right .info .logo img.not-on-front {
    margin: auto;
  }
}
/* line 1115, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .slogan {
  margin-bottom: 10px;
}
/* line 1119, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .zp-contact {
  margin-top: 15px;
}
/* line 1121, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .zp-contact p {
  margin: 0 !important;
}
/* line 1125, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .zp-contact .zp-local-phones {
  opacity: 0.5;
  margin-top: 5px;
}
/* line 1131, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .info .shop-related-info-hint {
  margin-top: 15px;
}
/* line 1135, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .copyright {
  background: #2C5FC7;
  padding: 10px 10px 50px 10px;
  color: #6699FF;
}
/* line 1140, ../assets/sass/style_custom.scss */
.front #footer-wrapper footer .zp-footer-right .copyright {
  background: #eb84A6;
  color: #cc3366;
}
/* line 1145, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right a {
  color: white;
  text-decoration: underline;
}
/* line 1151, ../assets/sass/style_custom.scss */
.front #footer-wrapper footer .zp-footer-right {
  text-align: center;
  float: none;
}
/* line 1156, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .contact-direction-up {
  display: none;
}
/* line 1159, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-right .contact-direction-left {
  display: inline;
}
/* line 1164, ../assets/sass/style_custom.scss */
#footer-wrapper footer .zp-footer-left {
  width: 25%;
  height: 300px;
  float: left;
  padding: 40px 0 0;
  text-align: right;
  background: white url(/f/zp-design/back/kefir_13.jpg) no-repeat center 30px;
  background-size: cover;
}
/* line 1176, ../assets/sass/style_custom.scss */
#footer-wrapper footer .shop-footer {
  width: 25%;
  float: left;
  padding: 40px 0 0;
  text-align: right;
}
/* line 1181, ../assets/sass/style_custom.scss */
#footer-wrapper footer .shop-footer .inner-wrapper {
  padding: 20px 10px;
}
/* line 1182, ../assets/sass/style_custom.scss */
.z01 #footer-wrapper footer .shop-footer .inner-wrapper {
  background: white url(/f/zp-design/shops/spar-kh/header/h-7--water_11.jpg) no-repeat right;
  background-size: cover;
}
/* line 1186, ../assets/sass/style_custom.scss */
.z02 #footer-wrapper footer .shop-footer .inner-wrapper {
  background: white url(/f/zp-design/shops/vash-gastronom-nsk/header/vash-water-21.jpg) no-repeat right -15px;
  background-size: cover;
}
/* line 1190, ../assets/sass/style_custom.scss */
.z03 #footer-wrapper footer .shop-footer .inner-wrapper {
  background: white url(/f/zp-design/shops/gutenberg-nsk/header/gutenberg-water-1.jpg) no-repeat right -15px;
  background-size: cover;
}

@media (max-width: 768px) {
  /* line 1204, ../assets/sass/style_custom.scss */
  #footer-wrapper footer .zp-footer-right, #footer-wrapper footer .shop-footer, .shop-page #footer-wrapper footer .zp-footer-right, .shop-page #footer-wrapper footer .shop-footer {
    width: 100%;
    text-align: center;
    float: none;
    border-top: 1px solid #6699FF;
    padding: 0;
  }
  /* line 1211, ../assets/sass/style_custom.scss */
  #footer-wrapper footer .zp-footer-right .contact-direction-up, #footer-wrapper footer .shop-footer .contact-direction-up, .shop-page #footer-wrapper footer .zp-footer-right .contact-direction-up, .shop-page #footer-wrapper footer .shop-footer .contact-direction-up {
    display: inline;
  }
  /* line 1214, ../assets/sass/style_custom.scss */
  #footer-wrapper footer .zp-footer-right .contact-direction-left, #footer-wrapper footer .shop-footer .contact-direction-left, .shop-page #footer-wrapper footer .zp-footer-right .contact-direction-left, .shop-page #footer-wrapper footer .shop-footer .contact-direction-left {
    display: none;
  }
  /* line 1218, ../assets/sass/style_custom.scss */
  #footer-wrapper footer .zp-footer-left, .shop-page #footer-wrapper footer .zp-footer-left {
    display: none;
  }
}
/* Main content */
/* line 1228, ../assets/sass/style_custom.scss */
.cd-main-content {
  padding: 60px 15px 30px;
}

/* Breadcrumbs */
/* line 1233, ../assets/sass/style_custom.scss */
.breadcrumb {
  padding: 15px 0;
  margin-bottom: 0;
  background-color: transparent;
}
/* line 1237, ../assets/sass/style_custom.scss */
.breadcrumb a, .breadcrumb .nolink {
  color: #c3c3c3;
}

/* Sidebar */
/* Side bar Navigation section menu */
/*
.shop-page #block-menu-block-1 {
    display: none;
}
*/
/* Sidebar menu */
/* line 1257, ../assets/sass/style_custom.scss */
ul.menu li.active a,
ul.menu li a.active {
  color: #6699FF;
}

/* Facets */
/* line 1266, ../assets/sass/style_custom.scss */
.block-facetapi ul,
.block-current-search ul {
  padding-left: 3px;
}
/* line 1269, ../assets/sass/style_custom.scss */
.block-facetapi li,
.block-current-search li {
  /* Fix for mobile chrom stairs effect for li */
  list-style: none;
  clear: both;
}
/* line 1274, ../assets/sass/style_custom.scss */
.block-facetapi input,
.block-current-search input {
  margin-right: 6px !important;
}
/* line 1278, ../assets/sass/style_custom.scss */
.block-facetapi h2,
.block-facetapi .cat-name,
.block-current-search h2,
.block-current-search .cat-name {
  color: gray;
  margin: 25px 0 10px;
  font-size: 20px;
  line-height: 1;
}
/* line 1287, ../assets/sass/style_custom.scss */
.block-facetapi .current-search-item-description,
.block-current-search .current-search-item-description {
  color: #c5c5c5;
  font-style: oblique;
}
/* line 1291, ../assets/sass/style_custom.scss */
.block-facetapi .cat-other,
.block-current-search .cat-other {
  color: #6699FF;
  margin-top: 20px;
}

/* line 1298, ../assets/sass/style_custom.scss */
#block-current-search-standard,
#block-current-search-articles-current-search {
  background: #d3d3d338;
  margin-bottom: 10px;
}
/* line 1302, ../assets/sass/style_custom.scss */
#block-current-search-standard h2, #block-current-search-standard h3,
#block-current-search-articles-current-search h2,
#block-current-search-articles-current-search h3 {
  font-size: 20px;
  background: #6699FF;
  color: white;
  padding: 10px;
  margin: 0;
  border-bottom: 5px solid white;
}
/* line 1310, ../assets/sass/style_custom.scss */
#block-current-search-standard .current-search-item,
#block-current-search-articles-current-search .current-search-item {
  padding: 5px 10px;
}
/* line 1312, ../assets/sass/style_custom.scss */
#block-current-search-standard .current-search-item.current-search-item-active,
#block-current-search-articles-current-search .current-search-item.current-search-item-active {
  font-size: 15px;
  color: #2c5fc7;
  font-weight: bold;
  padding-bottom: 0;
}
/* line 1318, ../assets/sass/style_custom.scss */
#block-current-search-standard .current-search-item.current-search-item-text,
#block-current-search-articles-current-search .current-search-item.current-search-item-text {
  font-style: italic;
  color: gray;
}
/* line 1322, ../assets/sass/style_custom.scss */
#block-current-search-standard .current-search-item.current-search-item-results,
#block-current-search-articles-current-search .current-search-item.current-search-item-results {
  padding: 0 !important;
}

/* line 1328, ../assets/sass/style_custom.scss */
#block-jquerymenu-1 .jquerymenu.jquerymenu-processed {
  position: relative;
  overflow: auto;
}
/* line 1332, ../assets/sass/style_custom.scss */
#block-jquerymenu-1 .jquerymenu.jquerymenu-processed li span {
  background-color: transparent !important;
}
/* line 1335, ../assets/sass/style_custom.scss */
#block-jquerymenu-1 .jquerymenu.jquerymenu-processed span.zp-expand {
  font-size: 20px;
  padding: 0 8px 0 4px;
  position: relative;
  top: -5px;
  font-family: ui-monospace;
  font-weight: bold;
}

/* line 1346, ../assets/sass/style_custom.scss */
.facetapi-facetapi-checkbox-links.facetapi-facet-field-article-product-depts span.zp-expand {
  font-size: 20px;
  font-family: ui-monospace;
  font-weight: bold;
  position: relative;
  top: -5px;
  padding: 0 8px 0 4px;
}
@media (max-width: 767px) {
  /* line 1346, ../assets/sass/style_custom.scss */
  .facetapi-facetapi-checkbox-links.facetapi-facet-field-article-product-depts span.zp-expand {
    padding: 0 15px;
  }
}

/* line 1361, ../assets/sass/style_custom.scss */
.facet-header-mobile h2 .i-wrapper {
  display: none;
}

@media (max-width: 767px) {
  /* line 1368, ../assets/sass/style_custom.scss */
  .node-article .field-name-body p {
    font-size: 16px;
  }

  /* line 1372, ../assets/sass/style_custom.scss */
  #user-side-menu,
  #block-zp-blocks-zp-catalog-sidemenu {
    margin-top: 15px;
  }
  /* line 1375, ../assets/sass/style_custom.scss */
  #user-side-menu h2,
  #block-zp-blocks-zp-catalog-sidemenu h2 {
    margin: 0 0 10px 0;
    background: #aaaaaa;
    color: white;
    padding: 5px;
  }
  /* line 1380, ../assets/sass/style_custom.scss */
  #user-side-menu h2 .icon,
  #block-zp-blocks-zp-catalog-sidemenu h2 .icon {
    display: none;
  }
  /* line 1384, ../assets/sass/style_custom.scss */
  #user-side-menu h2 .b,
  #block-zp-blocks-zp-catalog-sidemenu h2 .b {
    font-size: 13px;
    white-space: nowrap;
    position: relative;
    top: -7px;
  }

  /* line 1396, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2.opened i.icon-pointer.opened {
    display: block;
    float: left;
    margin-right: 10px;
  }
  /* line 1401, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2.opened i.icon-pointer.closed {
    display: none;
  }
  /* line 1405, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2.closed i.icon-pointer.closed {
    display: block;
    float: left;
    margin-right: 10px;
  }

  /* line 1415, ../assets/sass/style_custom.scss */
  .facet-header-mobile li a,
  #user-side-menu li a {
    color: #6699FF !important;
  }
  /* line 1417, ../assets/sass/style_custom.scss */
  .facet-header-mobile li a:hover,
  #user-side-menu li a:hover {
    color: #2C5FC7;
    text-decoration: none;
    font-weight: bold;
  }
  /* line 1423, ../assets/sass/style_custom.scss */
  .facet-header-mobile h2,
  #user-side-menu h2 {
    background: #6699FF !important;
  }
  /* line 1426, ../assets/sass/style_custom.scss */
  .facet-header-mobile h2.opened .opened,
  #user-side-menu h2.opened .opened {
    display: block !important;
    float: left;
    margin-right: 10px;
  }
  /* line 1431, ../assets/sass/style_custom.scss */
  .facet-header-mobile h2.opened .closed,
  #user-side-menu h2.opened .closed {
    display: none;
  }
  /* line 1435, ../assets/sass/style_custom.scss */
  .facet-header-mobile h2.closed .closed,
  #user-side-menu h2.closed .closed {
    display: block;
    float: left;
    margin-right: 10px;
  }

  /* line 1444, ../assets/sass/style_custom.scss */
  .facet-header-mobile {
    background: #6699FF;
    cursor: pointer;
  }
  /* line 1447, ../assets/sass/style_custom.scss */
  .facet-header-mobile h2 {
    color: white;
    padding: 5px;
    margin-top: 8px;
  }
  /* line 1451, ../assets/sass/style_custom.scss */
  .facet-header-mobile h2 .i-wrapper {
    display: inline;
  }

  /* line 1457, ../assets/sass/style_custom.scss */
  #block-jquerymenu-1 .jquerymenu.jquerymenu-processed,
  #block-zp-blocks-zp-catalog-sidemenu .wrapper,
  .zp-facets-collapsible.mobileHide {
    max-height: 230px;
    overflow: auto;
    width: 100%;
    border: 1px solid lightgray;
    border-bottom: 2px solid lightgray;
    padding: 10px;
    margin-top: -8px;
    position: relative;
    font-size: 16px;
    line-height: 1.7;
    /*      
    h2, 
    .cat-name {
        font-size: 20px;
    }
    */
  }
  /* line 1474, ../assets/sass/style_custom.scss */
  #block-jquerymenu-1 .jquerymenu.jquerymenu-processed input,
  #block-zp-blocks-zp-catalog-sidemenu .wrapper input,
  .zp-facets-collapsible.mobileHide input {
    top: 2px !important;
    position: relative;
  }

  /* line 1487, ../assets/sass/style_custom.scss */
  .zp-facets-collapsible.mobileHide h2,
  .zp-facets-collapsible.mobileHide .cat-name {
    font-size: 20px;
  }

  /* line 1493, ../assets/sass/style_custom.scss */
  #block-menu-block-1 h2 {
    background: #d4d48f;
    color: white;
    cursor: pointer;
  }

  /* line 1500, ../assets/sass/style_custom.scss */
  #block-jquerymenu-1 h2 {
    background: #2C5FC7;
    color: white;
    cursor: pointer;
    padding: 3px 10px;
  }
  /* line 1507, ../assets/sass/style_custom.scss */
  #block-jquerymenu-1 .jquerymenu.jquerymenu-processed {
    padding: 10px 30px 10px 30px;
    background: #f3f3f382;
    margin-top: -3px;
    font-size: 18px;
  }
}
/* jQuery ui Duialog popups */
/* line 1524, ../assets/sass/style_custom.scss */
.ui-dialog {
  box-shadow: 3px 3px 7px 0px lightgray;
  top: 20% !important;
  position: fixed;
}
/* line 1529, ../assets/sass/style_custom.scss */
.ui-dialog .ui-dialog-buttonpane {
  border-color: lightgray;
}

/* line 1533, ../assets/sass/style_custom.scss */
.ui-draggable .ui-dialog-titlebar {
  display: none;
}

/* Modal login/register window */
/* line 1539, ../assets/sass/style_custom.scss */
.modal-footer .login-link,
.modal-footer .pass-link,
.modal-footer .register-link {
  padding: 0 10px;
}

/* Fix margins for collapsible fieldset, being collapsed */
/* line 1546, ../assets/sass/style_custom.scss */
html.js fieldset.collapsed {
  height: initial;
}

/* FooTable */
/* line 1559, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets {
  overflow: hidden;
}
/* line 1563, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-1 .views-exposed-widget {
  width: 32%;
  padding-right: 0;
  margin-left: 2%;
}
/* line 1568, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-1 .views-exposed-widget:first-child {
  margin-left: 0;
}
/* line 1574, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-2 #edit-timestamp-wrapper {
  width: 100%;
  padding-right: 0;
}
/* line 1578, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-2 #edit-timestamp-wrapper .form-type-textfield {
  float: left;
  width: 49%;
}
/* line 1582, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-2 #edit-timestamp-wrapper .form-type-textfield.form-item-timestamp-max {
  float: right;
  margin-top: -25px;
}
/* line 1589, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-3 .views-exposed-widget {
  width: 49%;
  padding-right: 0;
  float: right;
}
/* line 1594, ../assets/sass/style_custom.scss */
.views-exposed-form .block-widgets.row-3 .views-exposed-widget:first-child {
  float: left;
  margin-left: 0;
}
/* line 1600, ../assets/sass/style_custom.scss */
.views-exposed-form .form-submit {
  background-color: #2C5FC7;
  border-color: #2C5FC7;
}
/* line 1604, ../assets/sass/style_custom.scss */
.views-exposed-form .form-submit:hover {
  background-color: #6699FF;
  border-color: #6699FF;
}

/* line 1610, ../assets/sass/style_custom.scss */
.form-control.input-sm {
  width: auto;
}

/* line 1615, ../assets/sass/style_custom.scss */
.dataTables_wrapper .btn a {
  color: white;
}
/* line 1618, ../assets/sass/style_custom.scss */
.dataTables_wrapper .btn a:hover {
  color: white;
}
/* line 1622, ../assets/sass/style_custom.scss */
.dataTables_wrapper .btn.failed {
  background-color: red;
}
/* line 1625, ../assets/sass/style_custom.scss */
.dataTables_wrapper .btn .label {
  top: initial;
  padding: 0;
  font-size: inherit;
}
/* line 1630, ../assets/sass/style_custom.scss */
.dataTables_wrapper .btn .label-default, .dataTables_wrapper .btn .label-success {
  background: transparent;
}

/* line 1637, ../assets/sass/style_custom.scss */
table.dataTable thead .views-field-url.sorting:after, table.dataTable thead .views-field-url.sorting_asc:after, table.dataTable thead .views-field-url.sorting_desc:after {
  content: '';
}

/* Articles */
/* Fix for Reset button on the Articles and Brands pages */
/* line 1645, ../assets/sass/style_custom.scss */
.views-reset-button button.form-submit {
  color: white;
}

/* Related articles on depts and product pages */
/* line 1649, ../assets/sass/style_custom.scss */
.bl-related-articles h2 {
  margin-top: 60px;
  border-top: 1px dotted lightgray;
  line-height: 3;
}

/* Place normally Contextual tabs button */
/* line 1657, ../assets/sass/style_custom.scss */
#contextual-tabs {
  position: absolute;
  right: 20px;
  top: 40px;
  z-index: 2;
}
/* line 1663, ../assets/sass/style_custom.scss */
.not-logged-in #contextual-tabs, .guest #contextual-tabs {
  display: none;
}
/* line 1667, ../assets/sass/style_custom.scss */
#contextual-tabs .btn {
  min-height: 20px !important;
  height: 20px;
  border: none;
  background: #ecececc9;
}
/* line 1673, ../assets/sass/style_custom.scss */
#contextual-tabs .btn::after {
  content: "e:";
  position: relative;
  left: 5px;
  top: -10px;
  font-size: 20px;
  color: lightgray;
}

/* ZP Catalog */
/* line 1689, ../assets/sass/style_custom.scss */
.view .pagination .progress-disabled {
  /* Fix for incorrect ajax page change indicator */
  float: left !important;
}

/* line 1693, ../assets/sass/style_custom.scss */
.view-catalog-db-index .view-content {
  overflow: hidden;
}

/* line 1696, ../assets/sass/style_custom.scss */
.view-catalog-db-index {
    /*
    @media (max-width: 768px) {
        .img-wrapper {
            height: 165px;
            
            // Vertical align of image 
            // https://habr.com/ru/company/netcracker/blog/277433/
            // see above settings for the normal media with height 200px)
            position: relative;
            // V1
            line-height: 165px;

            // V2
            // ... nothing to do
        }
    }
    */
}
/* line 1697, ../assets/sass/style_custom.scss */
.view-catalog-db-index .views-row {
  width: 180px;
  float: left;
  overflow: hidden;
  margin: 0 10px 10px 0;
  text-align: center;
}
@media (max-width: 350px) {
  /* line 1697, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row {
    width: 100% !important;
  }
}
@media (min-width: 351px) and (max-width: 380px) {
  /* line 1709, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row .node-product-a.node-teaser .field-name-field-product {
    width: 80% !important;
  }
  /* line 1712, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row .p-status-1 .commerce-add-to-cart button {
    margin: 6px 0 6px 10px !important;
  }
  /* line 1715, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row .zp-wish {
    margin: 40px 0 0 7px !important;
  }
}
@media (max-width: 480px) {
  /* line 1697, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row {
    width: 47%;
  }
  /* line 1723, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row.views-row-even {
    margin-right: 0;
    float: right;
  }
  /* line 1728, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .views-row .node-product-a.node-teaser .field-name-field-product {
    width: 75%;
    margin: 0;
  }
}
/* line 1734, ../assets/sass/style_custom.scss */
.view-catalog-db-index .img-wrapper {
  height: 200px;
  position: relative;
  line-height: 200px;
}
/* line 1747, ../assets/sass/style_custom.scss */
.view-catalog-db-index .img-wrapper img {
  max-height: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  /*
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  */
}
/* line 1793, ../assets/sass/style_custom.scss */
.view-catalog-db-index .title-wrapper {
  height: 55px;
}
/* line 1797, ../assets/sass/style_custom.scss */
.view-catalog-db-index .title-wrapper h3 {
            /*
            line-height: 0.9;
            max-height: 45px;
            overflow: hidden;
            margin-top: 0;

            // For open hidden lines of title on hover
            &:hover {
                height: 84px;
                max-height: 84px;
                position: absolute;
                top: 203px;
                background: white;
                z-index: 5;
                width: 99%;
                left: 1px;
            }

            a {
                font-size: 16px;
                color: #008068;
                text-decoration: none !important;
            }
            */
}
/* line 1828, ../assets/sass/style_custom.scss */
.view-catalog-db-index .title-wrapper h3 a {
  font-size: 16px;
  color: #6699FF;
  text-decoration: none !important;
  position: absolute;
  bottom: 100px;
  left: 3px;
  width: 95%;
  line-height: 1.3;
  max-height: 40px;
  overflow: hidden;
}
/* line 1840, ../assets/sass/style_custom.scss */
.view-catalog-db-index .title-wrapper h3 a:hover {
  max-height: inherit;
  overflow: visible;
  bottom: 99px;
  color: #2C5FC7;
  background: #fbfbfb;
}
/* line 1854, ../assets/sass/style_custom.scss */
.view-catalog-db-index .views-row-last {
  margin-right: 0;
}
/* line 1857, ../assets/sass/style_custom.scss */
.view-catalog-db-index .teaser {
  border: 1px solid lightgray;
  padding: 3px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

/* Product page/teaser */
/* line 1872, ../assets/sass/style_custom.scss */
.product-images .main-image a {
  display: block;
  cursor: zoom-in;
}
/* line 1876, ../assets/sass/style_custom.scss */
.product-images .main-image a img {
  max-height: 500px;
}
/* line 1880, ../assets/sass/style_custom.scss */
.product-images .thumbnails {
  overflow: hidden;
  margin: 1em 0;
  padding: 0;
  max-width: 200px;
}
/* line 1886, ../assets/sass/style_custom.scss */
.product-images .thumbnails li {
  display: inline-block;
  margin: 0 10px;
}
/* line 1891, ../assets/sass/style_custom.scss */
.product-images .thumbnails img {
  display: block;
  min-width: 100%;
  max-width: 100%;
}

/* line 1900, ../assets/sass/style_custom.scss */
.node-product-a .field-name-field-product {
  width: 115px;
  float: left;
}
/* line 1904, ../assets/sass/style_custom.scss */
.node-product-a.node-teaser .field-name-field-product {
  margin-left: 10px;
}
/* line 1908, ../assets/sass/style_custom.scss */
.node-product-a .field-name-field-categories-other {
  margin-top: 15px;
}
/* line 1910, ../assets/sass/style_custom.scss */
.node-product-a .field-name-field-categories-other .count-1 {
  font-weight: bold;
}

@media (max-width: 768px) {
  /* line 1923, ../assets/sass/style_custom.scss */
  .small-hero .header-inner .zp-logo-top {
    width: inherit;
  }

  /* line 1927, ../assets/sass/style_custom.scss */
  .bl-related-articles h2 {
    text-align: center;
  }

  /* line 1931, ../assets/sass/style_custom.scss */
  .node-product-a .content.page {
    text-align: center;
  }
  /* line 1934, ../assets/sass/style_custom.scss */
  .node-product-a .content.page h1 {
    display: none;
  }
  /* line 1937, ../assets/sass/style_custom.scss */
  .node-product-a .content.page .p-title-mobile {
    display: block !important;
    font-size: 33px;
    font-weight: 500;
    line-height: 1.1;
    color: gray;
    margin: 30px 0;
  }
  /* line 1948, ../assets/sass/style_custom.scss */
  .node-product-a .content.page .product-images {
    margin: auto;
    float: none;
  }
  /* line 1951, ../assets/sass/style_custom.scss */
  .node-product-a .content.page .product-images .thumbnails {
    max-width: inherit;
  }
  /* line 1956, ../assets/sass/style_custom.scss */
  .node-product-a .content.page .p-data {
    float: inherit;
    margin: auto;
  }
  /* line 1960, ../assets/sass/style_custom.scss */
  .node-product-a .content.page .p-data .p-status-1 {
    width: 160px;
    margin: auto;
  }
  /* line 1964, ../assets/sass/style_custom.scss */
  .node-product-a .content.page .p-data .p-status-0 {
    margin: auto;
    width: 50%;
  }
}
/* line 1974, ../assets/sass/style_custom.scss */
.q-wrapper {
  width: 30px;
  float: left;
  line-height: 1;
  text-align: center;
}
/* line 1979, ../assets/sass/style_custom.scss */
.q-wrapper a {
  padding: 0 3px !important;
}

/* line 1983, ../assets/sass/style_custom.scss */
.form-item-quantity {
  margin: 0;
}
/* line 1985, ../assets/sass/style_custom.scss */
.form-item-quantity label {
  display: none;
}
/* line 1988, ../assets/sass/style_custom.scss */
.form-item-quantity input {
  padding: 0 0 0 10px;
  height: 18px;
  border: none;
  box-shadow: none;
}

/* line 1996, ../assets/sass/style_custom.scss */
.commerce-quantity-plusminus-link a.button {
  color: #bbb9b9;
  background: none !important;
  text-decoration: none;
}
/* line 2001, ../assets/sass/style_custom.scss */
.view-commerce-cart-form .commerce-quantity-plusminus-link {
  /* Hide +- in Cart */
  display: none;
}

/* line 2006, ../assets/sass/style_custom.scss */
.commerce-quantity-plusminus-link-decrease {
  position: relative;
  bottom: 1px;
}

/* line 2011, ../assets/sass/style_custom.scss */
.qmeasure {
  color: #bfbfbf;
  /* Hide measure for not active product*/
}
/* line 2014, ../assets/sass/style_custom.scss */
.p-status-0 .qmeasure {
  display: none;
}

/* For not active product*/
/* line 2022, ../assets/sass/style_custom.scss */
.teaser .p-status-0 {
  height: 67px;
}
/* line 2024, ../assets/sass/style_custom.scss */
.teaser .p-status-0 button {
  border: none;
  margin-top: 20px;
}

/* line 2031, ../assets/sass/style_custom.scss */
.p-status-0 .commerce-add-to-cart {
  margin-top: 10px;
}

/* line 2035, ../assets/sass/style_custom.scss */
.p-status-1 {
  position: relative;
  overflow: hidden;
}

/* line 2042, ../assets/sass/style_custom.scss */
.page-user-wishlist .commerce-add-to-cart button,
.p-status-1 .view-catalog-db-index button,
.p-status-1 .commerce-add-to-cart button {
  margin: 6px 0 6px 15px !important;
  height: 40px !important;
  background: url(/sites/all/themes/zp_bootstrap/img/icons/cart-icon-white-30.png) no-repeat 14px 7px #6699FF !important;
  border: none !important;
  text-indent: -99999em;
  width: 60px !important;
}
/* line 2053, ../assets/sass/style_custom.scss */
.page-user-wishlist .commerce-add-to-cart button:hover,
.p-status-1 .view-catalog-db-index button:hover,
.p-status-1 .commerce-add-to-cart button:hover {
  background: url(/sites/all/themes/zp_bootstrap/img/icons/cart-icon-white-30.png) no-repeat 14px 7px #2C5FC7 !important;
}

/* line 2061, ../assets/sass/style_custom.scss */
.page-user-wishlist .commerce-add-to-cart button {
  margin: 0 !important;
}

/* Product page */
/* line 2069, ../assets/sass/style_custom.scss */
.node-product-a .product-images {
  float: left;
  margin-right: 40px;
}

/* line 2073, ../assets/sass/style_custom.scss */
.p-data {
  float: left;
  max-width: 400px;
}
/* line 2076, ../assets/sass/style_custom.scss */
.p-data h1 {
  font-size: 33px;
  color: gray;
  margin-bottom: 20px;
  max-width: 400px;
}
/* line 2082, ../assets/sass/style_custom.scss */
.p-data .bar {
  margin-bottom: 30px;
}
/* line 2085, ../assets/sass/style_custom.scss */
.p-data .field-name-field-brand-name {
  margin: 20px 0 30px;
}
/* line 2088, ../assets/sass/style_custom.scss */
.p-data .field-name-body {
  margin: 30px 0 30px 0;
}
/* line 2091, ../assets/sass/style_custom.scss */
.p-data .field-name-field-u-product-comment {
  max-width: 350px;
  margin-top: 40px;
}
/* line 2095, ../assets/sass/style_custom.scss */
.p-data .field-commerce-price {
  font-size: 25px;
  margin-top: 40px;
}

/* line 2103, ../assets/sass/style_custom.scss */
.p-data .psuffix {
  font-size: 15px;
  color: gray;
}
/* line 2107, ../assets/sass/style_custom.scss */
.node-product-a.node-teaser .psuffix {
  color: #cecece;
}

/* Admin menu AND navbar */
/* line 2118, ../assets/sass/style_custom.scss */
body.admin-menu.logged-in {
  margin-top: 0 !important;
}

/* line 2121, ../assets/sass/style_custom.scss */
body.navbar-vertical {
  padding-top: 0 !important;
}

/* line 2124, ../assets/sass/style_custom.scss */
#admin-menu {
  position: absolute !important;
}

@media (max-width: 768px) {
  /* line 2129, ../assets/sass/style_custom.scss */
  #admin-menu #admin-menu-users {
    display: none;
  }
  /* line 2132, ../assets/sass/style_custom.scss */
  #admin-menu #admin-menu-account li {
    display: none;
  }
  /* line 2134, ../assets/sass/style_custom.scss */
  #admin-menu #admin-menu-account li.admin-menu-account {
    display: list-item;
  }
}
@media (max-width: 580px) {
  /* line 2141, ../assets/sass/style_custom.scss */
  #admin-menu {
    display: none;
  }
}
@media (min-width: 580px) {
  /* line 2146, ../assets/sass/style_custom.scss */
  nav#navbar-administration {
    display: none;
  }
}
/* Error messages popup window*/
/* line 2156, ../assets/sass/style_custom.scss */
a.modal-close-button {
  color: #6699FF;
  outline: none !important;
}

/* line 2161, ../assets/sass/style_custom.scss */
a.static-login-link,
a.static-register-link,
a.modal-login-link {
  color: #6699FF;
}

/* Cart and Checkout panes */
/* line 2168, ../assets/sass/style_custom.scss */
.current-shop-link {
  margin-bottom: 15px;
}
/* line 2170, ../assets/sass/style_custom.scss */
.current-shop-link .address {
  font-size: 12px;
  color: #ababab;
}

/* line 2176, ../assets/sass/style_custom.scss */
.page-cart .img-wrapper img, .page-checkout .img-wrapper img, .page-user .img-wrapper img, .page-admin-commerce-orders .img-wrapper img {
  border: 1px solid lightgray;
  margin-top: 10px;
}

/* line 2181, ../assets/sass/style_custom.scss */
.checkout-help {
  /* Review your order before sumit*/
  margin-bottom: 15px;
  font-weight: bold;
  color: palevioletred;
}

/* Payment on site chosen - message on the Order complete page */
/* line 2191, ../assets/sass/style_custom.scss */
.payment-on-site-notice {
  color: #00889e;
}

/* Pay button on an user order view page */
/* line 2195, ../assets/sass/style_custom.scss */
.view-commerce-line-item-table {
  position: relative;
}

/* line 2199, ../assets/sass/style_custom.scss */
.user-order-payment-info {
  font-size: 16px;
}
/* line 2201, ../assets/sass/style_custom.scss */
.user-order-payment-info.paid {
  color: green;
}
/* line 2204, ../assets/sass/style_custom.scss */
.user-order-payment-info.not-paid {
  color: red;
}
/* line 2207, ../assets/sass/style_custom.scss */
.user-order-payment-info.partly-paid {
  color: orange;
}
/* line 2210, ../assets/sass/style_custom.scss */
.user-order-payment-info.over-paid {
  color: violet;
}

/* line 2216, ../assets/sass/style_custom.scss */
.pay-now-button {
  margin-top: 10px;
}

/* line 2219, ../assets/sass/style_custom.scss */
.field-name-commerce-customer-shipping {
  margin-top: 40px;
}
/* line 2221, ../assets/sass/style_custom.scss */
.field-name-commerce-customer-shipping .field-label {
  color: #01959c;
}
/* line 2224, ../assets/sass/style_custom.scss */
.field-name-commerce-customer-shipping .field-items {
  margin-top: 10px;
}
/* line 2226, ../assets/sass/style_custom.scss */
.field-name-commerce-customer-shipping .field-items .field-items {
  margin-top: 0;
}
/* line 2229, ../assets/sass/style_custom.scss */
.field-name-commerce-customer-shipping .field-items .field-items .field-label {
  color: #d0cece;
}

/* Captcha improve */
/* line 2239, ../assets/sass/style_custom.scss */
.form-item-captcha-response label {
  display: none;
}

/* Wishlist heart */
/* line 2246, ../assets/sass/style_custom.scss */
.zp-wish {
  float: left;
  margin: 40px 0 0 20px;
}
/* line 2249, ../assets/sass/style_custom.scss */
.zp-wish a.progress-disabled {
  visibility: hidden;
}
/* line 2252, ../assets/sass/style_custom.scss */
.zp-wish .ajax-progress-throbber {
  display: none;
  /* Prevent from heart style breaking while throbing*/
}

/* line 2256, ../assets/sass/style_custom.scss */
.wish-off {
  color: lightgray;
}

/* line 2259, ../assets/sass/style_custom.scss */
.wish-on {
  color: #fbaaaa;
}

/* Wishlist page for anonymous */
/* line 2263, ../assets/sass/style_custom.scss */
.page-user-wishlist.not-logged-in .nav-tabs {
  display: none;
}

/* USer pages */
/* line 2269, ../assets/sass/style_custom.scss */
.page-user .main-container {
  padding-top: 30px;
}

/* User side menu */
/* line 2273, ../assets/sass/style_custom.scss */
#user-side-menu {
  text-align: right;
  padding: 40px 30px 40px 0;
  border-right: 1px solid lightgray;
}
/* line 2277, ../assets/sass/style_custom.scss */
#user-side-menu li {
  list-style: none;
  margin-bottom: 10px;
}
/* line 2280, ../assets/sass/style_custom.scss */
#user-side-menu li a {
  color: gray;
}
/* line 2282, ../assets/sass/style_custom.scss */
#user-side-menu li a.active {
  color: #6699FF;
  font-weight: bold;
}
@media (max-width: 768px) {
  /* line 2273, ../assets/sass/style_custom.scss */
  #user-side-menu {
    text-align: left;
    border: none;
    padding: 0;
    margin-bottom: 20px;
  }
  /* line 2293, ../assets/sass/style_custom.scss */
  #user-side-menu .content {
    background: #f5f3f3;
    padding: 10px 20px;
    border-top: solid 2px white;
  }
  /* line 2297, ../assets/sass/style_custom.scss */
  #user-side-menu .content li {
    list-style: circle;
  }
  /* line 2301, ../assets/sass/style_custom.scss */
  #user-side-menu h2 {
    padding: 10px;
    margin: 0;
    background: lightgray;
    color: white;
    cursor: pointer;
  }
}

/* Home page */
/* line 2317, ../assets/sass/style_custom.scss */
.front {
  /* Front page shops list block */
}
/* line 2318, ../assets/sass/style_custom.scss */
.front .cd-main-content.sub-nav-hero.secondary-nav-fixed {
  margin-top: 0;
}
/* line 2321, ../assets/sass/style_custom.scss */
.front .sticky-header {
  position: relative;
}
/* line 2323, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-hero {
  height: inherit;
}
/* line 2325, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-hero .cd-hero-content {
  display: block;
  margin-top: 10px;
}
/* line 2329, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-hero .cd-hero-content .front-image.main img {
  margin-top: 10px;
  max-width: 400px;
  width: 98%;
}
/* line 2340, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav {
  display: none;
  height: 60px;
  background: #6699FF;
  border-bottom: 2px solid white;
}
/* line 2342, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav.fixed {
  display: block;
}
/* line 2349, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm {
  background: #6699FF;
}
/* line 2355, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 .main-menu li a {
  white-space: normal;
}
/* line 2358, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 .main-menu li.first {
  position: relative;
}
/* line 2360, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 .main-menu li.first .dropdown-menu {
  left: 0;
  right: initial;
  min-width: 200px;
}
/* line 2366, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 .main-menu li.last {
  position: relative;
}
/* line 2368, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 .main-menu li.last .dropdown-menu {
  right: 0;
}
/* line 2378, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm #lang_dropdown_form_language select {
  color: white;
  border-color: white;
}
/* line 2384, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm .navbar-nav > li > span.nolink {
  color: white;
}
/* line 2387, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm .navbar-nav > li > a {
  color: white;
  top: 0;
}
/* line 2392, ../assets/sass/style_custom.scss */
.front .sticky-header .cd-secondary-nav .navbar-default.yamm .navbar-nav > li.zp-shops-menu-link a {
  color: white !important;
}
@media (max-width: 992px) {
  /* line 2401, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm button {
    float: right !important;
    border-color: white;
  }
  /* line 2405, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm button:focus, .front .sticky-header .cd-secondary-nav .navbar-default.yamm button:hover {
    background: #6699FF !important;
  }
  /* line 2408, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm button span {
    background: white;
  }
  /* line 2412, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #lang_dropdown_form_language {
    top: 8px;
  }
  /* line 2416, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 {
    padding: 0;
    background: white;
    border-bottom: 2px solid #6699FF;
    font-size: 17px;
    margin-top: 8px;
  }
  /* line 2423, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul {
    margin: 0;
  }
  /* line 2427, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li {
    border-bottom: 1px solid #6699FF;
  }
  /* line 2432, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li a, .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li span {
    color: #6699FF !important;
  }
  /* line 2435, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li.open {
    background: #d3d3d333;
  }
  /* line 2436, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li.open span.dropdown-toggle {
    color: white !important;
  }
  /* line 2440, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li.open .usermenu-email {
    padding: 5px 15px 10px 25px;
  }
  /* line 2442, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul > li.open .usermenu-email span {
    color: gray !important;
  }
  /* line 2449, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul li li {
    border-bottom: 1px solid #d3d3d366;
  }
  /* line 2451, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul li li.last {
    border-bottom: none;
  }
  /* line 2454, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul li li:hover {
    background: #d3d3d342;
  }
  /* line 2457, ../assets/sass/style_custom.scss */
  .front .sticky-header .cd-secondary-nav .navbar-default.yamm #navbar-collapse-1 ul li li a {
    color: #2C5FC7 !important;
    padding: 10px 25px 10px 0;
    font-size: 17px;
  }
}
@media (max-width: 468px) {
  /* line 2472, ../assets/sass/style_custom.scss */
  .front .sticky-header #navbar-collapse-1 {
    height: 100%;
    overflow-y: auto;
    max-height: 300px;
  }
}
/* line 2479, ../assets/sass/style_custom.scss */
.front img#gray-back-top {
  position: absolute;
  z-index: -10;
  height: 230px;
  bottom: 0;
  width: 100%;
}
/* line 2486, ../assets/sass/style_custom.scss */
.front .front-content-wrapper {
  position: relative;
}
/* line 2488, ../assets/sass/style_custom.scss */
.front .front-content-wrapper img#gray-back-middle {
  position: absolute;
  z-index: -10;
  height: 400px;
  top: 0;
  width: 100%;
}
@media (max-width: 580px) {
  /* line 2488, ../assets/sass/style_custom.scss */
  .front .front-content-wrapper img#gray-back-middle {
    display: none;
  }
}
/* line 2500, ../assets/sass/style_custom.scss */
.front .front-content-wrapper img#gray-back-middle-mobile {
  display: none;
}
@media (max-width: 580px) {
  /* line 2500, ../assets/sass/style_custom.scss */
  .front .front-content-wrapper img#gray-back-middle-mobile {
    display: block;
    position: absolute;
    z-index: -10;
    height: 400px;
    top: 0;
    width: 100%;
    height: 700px;
  }
}
/* line 2516, ../assets/sass/style_custom.scss */
.front .about {
  overflow: hidden;
  margin: 0px auto 40px;
  width: 100%;
  max-width: 700px;
  font-size: 16px;
}
@media (max-width: 580px) {
  /* line 2516, ../assets/sass/style_custom.scss */
  .front .about {
    margin-bottom: 10px;
  }
}
/* line 2525, ../assets/sass/style_custom.scss */
.front .about div {
  width: 33%;
  float: left;
  padding: 0 20px 20px 20px;
  text-align: center;
  position: relative;
  border-left: 1px dotted;
  margin: auto;
}
/* line 2534, ../assets/sass/style_custom.scss */
.front .about div .back-digit {
  position: absolute;
  font-size: 140px;
  color: #e0eef7;
  z-index: -1;
  font-weight: bold;
  top: -10px;
  left: 30px;
}
/* line 2543, ../assets/sass/style_custom.scss */
.front .about div:first-child {
  margin-top: 0;
  text-align: left;
  border: none;
}
/* line 2547, ../assets/sass/style_custom.scss */
.front .about div:first-child .back-digit {
  top: -30px;
}
/* line 2551, ../assets/sass/style_custom.scss */
.front .about div:last-child {
  text-align: right;
}
/* line 2553, ../assets/sass/style_custom.scss */
.front .about div:last-child .back-digit {
  top: -45px;
  right: 30px;
}
@media (max-width: 580px) {
  /* line 2525, ../assets/sass/style_custom.scss */
  .front .about div {
    float: none;
    border: none;
    text-align: center !important;
    width: 80%;
    margin: 30px auto 0px;
    border-top: 1px dotted;
    padding: 20px 0 0 0;
  }
  /* line 2568, ../assets/sass/style_custom.scss */
  .front .about div .back-digit {
    font-size: 80px;
    top: -10px;
    left: 20%;
  }
  /* line 2574, ../assets/sass/style_custom.scss */
  .front .about div:first-child .back-digit {
    top: -25px;
    left: 40%;
  }
  /* line 2580, ../assets/sass/style_custom.scss */
  .front .about div:last-child .back-digit {
    top: -20px;
    right: 30px;
  }
}
/* line 2589, ../assets/sass/style_custom.scss */
.front .arrow {
  background: transparent url(/f/zp-design/home/arrow_11.png) no-repeat center;
  margin: auto;
  background-size: contain !important;
}
/* line 2592, ../assets/sass/style_custom.scss */
.front .arrow.one {
  width: 40px;
  height: 40px;
  margin: 20px auto 10px;
}
/* line 2597, ../assets/sass/style_custom.scss */
.front .arrow.two {
  width: 60px;
  height: 60px;
}
/* line 2601, ../assets/sass/style_custom.scss */
.front .arrow.three {
  background: transparent url(/f/zp-design/home/arrow_21.png) no-repeat center;
  width: 60px;
  height: 60px;
}
/* line 2610, ../assets/sass/style_custom.scss */
.front #shops-list-block {
  width: 90%;
  margin: -30px auto 25px;
  padding-top: 40%;
  max-width: 500px;
  min-height: 400px;
  background: transparent url(/f/zp-design/home/where_to_go_11.png) no-repeat center top;
  background-size: contain;
}
@media (min-width: 620px) {
  /* line 2610, ../assets/sass/style_custom.scss */
  .front #shops-list-block {
    padding-top: 250px;
    min-height: 500px;
  }
}
@media (max-width: 620px) {
  /* line 2610, ../assets/sass/style_custom.scss */
  .front #shops-list-block {
    position: relative;
    left: 10px;
  }
}
/* line 2629, ../assets/sass/style_custom.scss */
.front #shops-list-block.uk {
  background: transparent url(/f/zp-design/home/where_to_go_11_uk.png) no-repeat center top;
  background-size: contain;
}
/* line 2633, ../assets/sass/style_custom.scss */
.front #shops-list-block.en {
  background: transparent url(/f/zp-design/home/where_to_go_11_en.png) no-repeat center top;
  background-size: contain;
}
/* line 2638, ../assets/sass/style_custom.scss */
.front #shops-list-block #get-shops {
  text-align: center;
  position: relative;
  left: -15px;
  color: white;
  /* border: 3px dashed #cc3366; */
  width: 200px;
  margin: 15px auto;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 20px;
  letter-spacing: 5px;
  background: #eb84a6;
  box-shadow: 0 10px 20px #00000047;
  /* background: white; */
  /* color: #cc3366; */
  font-weight: 500;
  /* border: 1px dotted #cc3366; */
  line-height: 30px;
  border: 3px solid #cc3366;
  border: 3px solid white;
  background: #cc3366;
  cursor: pointer;
}
/* line 2662, ../assets/sass/style_custom.scss */
.front #shops-list-block #get-shops:hover {
  background: #e06a91;
}
/* line 2668, ../assets/sass/style_custom.scss */
.front #shops-list-block #default-message {
  margin: auto;
  width: 300px;
  text-align: center;
  color: white;
  padding: 10px 0 60px;
  max-width: 200px;
  background: url(/sites/all/themes/zp_bootstrap/img/icons/loader-icon-1.gif) no-repeat center bottom;
  font-size: 20px;
  position: relative;
  left: -15px;
}
/* line 2682, ../assets/sass/style_custom.scss */
.front #shops-list-block .city {
  width: 60%;
  margin-left: 17%;
  margin-bottom: 20px;
}
/* line 2687, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .city-title, .front #shops-list-block .city .city-title a {
  color: #bfd4e3;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
}
/* line 2694, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops {
  position: relative;
  margin: -11px 0 0 11%;
  background: #bfd4e3;
  padding: 10px 20px 10px 20px;
}
/* line 2702, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops .shop .shop-title button {
  background: #cc3366;
  border: none;
  padding: 5px;
  border-bottom: 7px solid #eb84A6;
  margin: 20px 0 7px;
}
/* line 2708, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops .shop .shop-title button a {
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}
/* line 2715, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops .shop .shop-title button:hover {
  background: #eb84A6;
  border-bottom: 7px solid #cc3366;
}
/* line 2718, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops .shop .shop-title button:hover a {
  color: #cc3366;
}
/* line 2724, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops .shop .address {
  font-size: 13px;
  font-weight: bold;
  margin: 5px 0 10px 0;
}
/* line 2729, ../assets/sass/style_custom.scss */
.front #shops-list-block .city .shops .shop .teaser {
  font-size: 12px;
}
/* line 2739, ../assets/sass/style_custom.scss */
.front .you-need-us {
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding-right: 30px;
}
/* line 2744, ../assets/sass/style_custom.scss */
.front .you-need-us .caption {
  width: 90%;
  max-width: 450px;
  margin: 20px auto;
  min-height: 120px;
  background: transparent url(/f/zp-design/home/you_need_us_11.png) no-repeat center top;
  background-size: contain;
}
/* line 2752, ../assets/sass/style_custom.scss */
.front .you-need-us .caption.uk {
  background: transparent url(/f/zp-design/home/you_need_us_11_uk.png) no-repeat center top;
  background-size: contain;
}
/* line 2756, ../assets/sass/style_custom.scss */
.front .you-need-us .caption.en {
  background: transparent url(/f/zp-design/home/you_need_us_11_en.png) no-repeat center top;
  background-size: contain;
}
/* line 2762, ../assets/sass/style_custom.scss */
.front .you-need-us .content {
  overflow: hidden;
}
/* line 2764, ../assets/sass/style_custom.scss */
.front .you-need-us .content ul {
  float: left;
  margin: 0;
}
/* line 2767, ../assets/sass/style_custom.scss */
.front .you-need-us .content ul.one {
  text-align: right;
  width: 40%;
  border-right: 1px dotted gray;
  padding-right: 20px;
  color: #cc3366;
}
/* line 2774, ../assets/sass/style_custom.scss */
.front .you-need-us .content ul.two {
  width: 59%;
  float: left;
  padding-left: 30px;
}
/* line 2780, ../assets/sass/style_custom.scss */
.front .you-need-us .content ul li {
  margin-bottom: 20px;
  list-style-position: inside;
}
/* line 2787, ../assets/sass/style_custom.scss */
.front .you-need-us .slogan {
  width: 40%;
  text-align: right;
  font-size: 40px;
  padding: 20px;
  font-weight: bold;
  color: #cc3366;
  margin-top: -100px;
}
@media (max-width: 470px) {
  /* line 2739, ../assets/sass/style_custom.scss */
  .front .you-need-us {
    padding-right: 0;
  }
  /* line 2798, ../assets/sass/style_custom.scss */
  .front .you-need-us .caption {
    min-height: 80px;
    width: 100%;
  }
}
@media (max-width: 580px) {
  /* line 2805, ../assets/sass/style_custom.scss */
  .front .you-need-us ul.one, .front .you-need-us ul.two {
    float: none;
    text-align: center !important;
    width: 100% !important;
    border: none !important;
    margin: 0;
    padding: 0 30px;
    list-style: none;
  }
  /* line 2813, ../assets/sass/style_custom.scss */
  .front .you-need-us ul.one li, .front .you-need-us ul.two li {
    position: relative;
  }
  /* line 2815, ../assets/sass/style_custom.scss */
  .front .you-need-us ul.one li:after, .front .you-need-us ul.two li:after {
    content: "";
    background: lightgray;
    position: absolute;
    bottom: -13px;
    left: 35%;
    height: 1px;
    width: 30%;
  }
  /* line 2826, ../assets/sass/style_custom.scss */
  .front .you-need-us .slogan {
    margin: 20px auto;
    width: 100%;
    padding: 0;
    text-align: center;
    line-height: 1;
  }
}
/* line 2837, ../assets/sass/style_custom.scss */
.front .library {
  width: 90%;
  max-width: 550px;
  margin: -20px auto 30px;
}
/* line 2841, ../assets/sass/style_custom.scss */
.front .library .pic img {
  width: 100%;
}
/* line 2844, ../assets/sass/style_custom.scss */
.front .library .content {
  width: 90%;
  background: transparent url(/f/zp-design/home/library_caption_back_11.png) no-repeat center top;
  background-size: contain;
  min-height: 150px;
  margin: auto;
  padding-top: 55px;
}
/* line 2851, ../assets/sass/style_custom.scss */
.front .library .content div {
  color: white;
  text-align: center;
}
/* line 2854, ../assets/sass/style_custom.scss */
.front .library .content div.one {
  font-size: 12px;
  font-weight: bold;
}
/* line 2858, ../assets/sass/style_custom.scss */
.front .library .content div.two {
  font-size: 10px;
}
@media (max-width: 580px) {
  /* line 2837, ../assets/sass/style_custom.scss */
  .front .library {
    margin-top: 40px;
  }
}
/* line 2872, ../assets/sass/style_custom.scss */
.front footer .zp-footer-left {
  display: none;
}
/* line 2875, ../assets/sass/style_custom.scss */
.front footer .zp-footer-right.container {
  width: 84% !important;
  max-width: 550px;
}
/* line 2881, ../assets/sass/style_custom.scss */
.front img#footer-back {
  position: absolute;
  bottom: 0;
  z-index: -5;
  max-height: 300px;
  width: 100%;
}
/* line 2890, ../assets/sass/style_custom.scss */
.front .system-message .alert {
  background: white;
  max-width: 400px;
  margin: 40px auto;
}

/* Age restiction for Alcohol and Smoke pages */
/* line 2901, ../assets/sass/style_custom.scss */
.age-restricted .region.region-content {
  height: 12px;
  overflow: hidden;
}

/* line 2910, ../assets/sass/style_custom.scss */
.pagination > .active > span,
.btn-primary,
.btn-info,
.btn-success {
  background: #2C5FC7;
  border-color: #2C5FC7;
}
/* line 2916, ../assets/sass/style_custom.scss */
.pagination > .active > span:hover,
.btn-primary:hover,
.btn-info:hover,
.btn-success:hover {
  background-color: #6699FF;
  border-color: #6699FF;
}

/* line 2922, ../assets/sass/style_custom.scss */
.alert-success {
  color: #337ab7d9;
  background: #c9e4ff52;
  border: none;
  margin-top: 20px;
}

/* line 2928, ../assets/sass/style_custom.scss */
.alert-danger {
  color: #e23d82;
  background: #fd6ff91c;
  margin-top: 20px;
}

/* line 2933, ../assets/sass/style_custom.scss */
.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #e23d82;
}

/* line 2937, ../assets/sass/style_custom.scss */
.captcha {
  margin-bottom: 20px;
}

/* line 2942, ../assets/sass/style_custom.scss */
button .glyphicon-log-in {
  padding-right: 10px;
}

/* line 2954, ../assets/sass/style_custom.scss */
#block-zp-blocks-zp-catalog-sidemenu h2 {
  cursor: pointer;
}
/* line 2956, ../assets/sass/style_custom.scss */
#block-zp-blocks-zp-catalog-sidemenu h2 .icon-pointer {
  display: none;
}
@media (min-width: 768px) {
  /* line 2961, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2 {
    margin-top: 5px;
    max-width: 250px;
  }
  /* line 2964, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2 .b {
    display: inline-block;
    font-size: 16px;
    position: relative;
    top: -10px;
    color: lightgray;
  }
  /* line 2971, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2 .icon {
    font-size: 15px;
    float: left;
    position: relative;
    top: 3px;
    margin-right: 5px;
    color: lightgray;
  }
  /* line 2979, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu h2 .icon-pointer {
    display: none !important;
  }
  /* line 2983, ../assets/sass/style_custom.scss */
  #block-zp-blocks-zp-catalog-sidemenu .wrapper {
    margin-top: -10px;
  }
}

/* line 2990, ../assets/sass/style_custom.scss */
.side-navigation-tree {
  position: relative;
  color: #6699FF;
}
/* line 3001, ../assets/sass/style_custom.scss */
.side-navigation-tree span {
  font-size: 13px;
  font-style: italic;
  letter-spacing: .4px;
  color: #a8a8a8;
}
/* line 3008, ../assets/sass/style_custom.scss */
.side-navigation-tree .fa-folder-open, .side-navigation-tree .fa-folder {
  color: #007bff;
}
/* line 3012, ../assets/sass/style_custom.scss */
.side-navigation-tree .fa-html5 {
  color: #f21f10;
}
/* line 3016, ../assets/sass/style_custom.scss */
.side-navigation-tree ul {
  padding-left: 5px;
  list-style: none;
}
/* line 3020, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 3029, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li:before {
  position: absolute;
  top: 15px;
  left: 0;
  width: 10px;
  height: 1px;
  margin: auto;
  content: '';
  background-color: #666;
}
/* line 3040, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #666;
}
/* line 3051, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li:last-child:after {
  height: 15px;
}
/* line 3055, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li a {
  color: #6699FF;
  cursor: pointer;
}
/* line 3058, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li a:hover {
  text-decoration: none;
}
/* line 3061, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li a.leaf {
  padding-left: 5px;
}
/* line 3064, ../assets/sass/style_custom.scss */
.side-navigation-tree ul li a.active {
  color: #2C5FC7;
  font-weight: bold;
}

/* line 3076, ../assets/sass/style_custom.scss */
.view-commerce-addressbook table td {
  display: block;
  margin-bottom: 30px;
}

/* line 3083, ../assets/sass/style_custom.scss */
form#hybridauth-user-identity-delete div {
  margin-bottom: 20px;
}
/* line 3086, ../assets/sass/style_custom.scss */
form#hybridauth-user-identity-delete .form-actions {
  margin-top: 30px;
}

/* line 3094, ../assets/sass/style_custom.scss */
.shop-delivery-info {
  margin-bottom: 30px;
}
/* line 3096, ../assets/sass/style_custom.scss */
.shop-delivery-info h3 {
  display: block;
}
/* line 3098, ../assets/sass/style_custom.scss */
.shop-delivery-info h3 a {
  background: #6699FF;
  color: white;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  display: block;
}
/* line 3105, ../assets/sass/style_custom.scss */
.shop-delivery-info h3 a span {
  font-size: 15px;
}
/* line 3108, ../assets/sass/style_custom.scss */
.shop-delivery-info h3 a i {
  margin-right: 10px;
}
/* line 3113, ../assets/sass/style_custom.scss */
.shop-delivery-info h4 {
  color: gray;
  font-size: 14px;
  background: #d3d3d3a8;
  padding: 10px;
  margin-top: -5px;
  cursor: pointer;
}
/* line 3121, ../assets/sass/style_custom.scss */
.shop-delivery-info .info-wrapper {
  padding: 10px;
  background: #d3d3d31f;
  border-bottom: 3px solid #6699ff;
}
/* line 3125, ../assets/sass/style_custom.scss */
.shop-delivery-info .info-wrapper strong {
  color: #6699FF;
}

/* line 3131, ../assets/sass/style_custom.scss */
.clarify {
  color: #6699FF;
  font-style: italic;
  margin-top: 15px;
}

/* line 3138, ../assets/sass/style_custom.scss */
.deny-message a {
  text-decoration: none;
}

/* line 3143, ../assets/sass/style_custom.scss */
.hybridauth-widget li {
  margin-right: 10px !important;
}

/* line 3148, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 {
  width: 100% !important;
}
/* line 3151, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 td.views-field-edit-quantity {
  width: 100px;
  min-width: 60px;
}
/* line 3154, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 td.views-field-edit-quantity .form-item {
  width: 50px;
  float: left;
  margin-right: 5px;
}
/* line 3160, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 .field-name-field-u-product-comment {
  max-width: 120px;
}
@media (min-width: 762px) {
  /* line 3160, ../assets/sass/style_custom.scss */
  .page-cart .col-sm-9 .field-name-field-u-product-comment {
    max-width: 250px;
  }
}
/* line 3170, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 #edit-submit {
  background: #e0e0e08a;
  border: 1px solid lightgray;
  margin-right: 5px;
  color: gray;
  border: 1px solid #d3d3d37a;
}
/* line 3176, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 #edit-submit span {
  display: none;
}
/* line 3179, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 #edit-submit:hover {
  background: #8080806e;
  color: white;
}
/* line 3184, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 #edit-checkout {
  background: #2C5FC7;
  color: white;
  border: none;
}
/* line 3188, ../assets/sass/style_custom.scss */
.page-cart .col-sm-9 #edit-checkout:hover {
  background: #6699FF;
}

/* line 3197, ../assets/sass/style_custom.scss */
.page-checkout .checkout-buttons .checkout-continue {
  background: #2C5FC7;
  color: white;
  border: 1px solid #2C5FC7;
}
/* line 3201, ../assets/sass/style_custom.scss */
.page-checkout .checkout-buttons .checkout-continue:hover {
  background: #6699FF;
  border: 1px solid #6699FF;
}
/* line 3206, ../assets/sass/style_custom.scss */
.page-checkout .checkout-buttons .checkout-cancel {
  background: #d3d3d382 !important;
  color: gray !important;
  margin-left: 10px;
  padding: 7px 12px !important;
}
/* line 3211, ../assets/sass/style_custom.scss */
.page-checkout .checkout-buttons .checkout-cancel:hover {
  background: lightgray !important;
  color: white !important;
  text-decoration: none !important;
}
/* line 3217, ../assets/sass/style_custom.scss */
.page-checkout .checkout-buttons .button-operator {
  margin-left: 10px;
  color: gray;
}

/* line 3224, ../assets/sass/style_custom.scss */
.grumble-text .inner {
  line-height: 1.3;
}

/* line 3229, ../assets/sass/style_custom.scss */
div.call-to-us {
  color: #e23d82;
}

/* line 3233, ../assets/sass/style_custom.scss */
.node-unpublished {
  background-color: transparent;
}
/* line 3236, ../assets/sass/style_custom.scss */
.node-unpublished h1::after {
  content: "---Unpublished---";
  font-size: 15px;
  color: white;
  position: relative;
  top: -30px;
  background: #e23d82;
  padding: 3px 10px;
}

/* line 3248, ../assets/sass/style_custom.scss */
.color-a {
  color: #6699FF;
}

/* line 3255, ../assets/sass/style_custom.scss */
.alert-info {
  background: #c3c3c33b;
  border: none;
  color: #8080807a;
  padding-bottom: 5px;
}
/* line 3261, ../assets/sass/style_custom.scss */
.alert-info .glyphicon {
  display: none;
}

/* line 3267, ../assets/sass/style_custom.scss */
.search-wrapper .scope-wrapper {
  margin: 5px 0 3px;
  overflow: hidden;
}
/* line 3271, ../assets/sass/style_custom.scss */
.search-wrapper .scope-wrapper .shop-page-descr {
  display: none;
}
/* line 3273, ../assets/sass/style_custom.scss */
.node-type-shop .search-wrapper .scope-wrapper .shop-page-descr {
  display: block;
}
/* line 3277, ../assets/sass/style_custom.scss */
.search-wrapper .scope-wrapper p {
  float: left;
  margin: 0 10px 0 0;
}
/* line 3280, ../assets/sass/style_custom.scss */
.search-wrapper .scope-wrapper p input {
  margin-right: 5px;
}
/* line 3284, ../assets/sass/style_custom.scss */
.node-type-shop .search-wrapper .scope-wrapper p {
  display: none;
}

/* line 3292, ../assets/sass/style_custom.scss */
.select2-result-repository .type-d {
  font-weight: bold;
}
/* line 3295, ../assets/sass/style_custom.scss */
.select2-result-repository .type-pt {
  font-weight: bold;
  font-style: italic;
}
/* line 3299, ../assets/sass/style_custom.scss */
.select2-result-repository .type-confine {
  font-weight: bold;
  color: #e23d82;
}

/*
.view-catalog-db-index .teaser {
    border: 1px solid lightgray;
    padding: 3px;
    border-radius: 5px;
    position: relative;
}
*/
/* Product search images button on teasers and a page */
/* line 3321, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img, .product-images.default-img {
  position: relative;
}
/* line 3323, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img img, .product-images.default-img img {
  height: 75%;
  vertical-align: bottom;
  margin-bottom: -25px;
}
/* line 3328, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper, .product-images.default-img .search-wrapper {
  cursor: zoom-in;
  overflow: hidden;
  position: absolute;
  width: 91%;
  left: 9px;
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  z-index: 10;
  font-weight: bold;
  color: #dadada;
}
/* line 3340, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .no-img, .product-images.default-img .search-wrapper .no-img {
  float: left;
  width: 37%;
  text-align: right;
  border-radius: 10px 0;
  padding: 11px;
  padding-right: 5px;
  background: none;
  border: 1px solid #d3d3d347;
}
/* line 3351, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .find-img, .product-images.default-img .search-wrapper .find-img {
  float: left;
  width: 58%;
  text-align: left;
  background: #d3d3d370;
  border-radius: 0 10px;
  padding: 10px;
  padding-left: 5px;
  margin-left: 4px;
  margin-top: 7px;
  color: #80808057;
  background: #d3d3d330;
  border: 1px solid #d3d3d359;
  position: relative;
}
@media (max-width: 350px) {
  /* line 3351, ../assets/sass/style_custom.scss */
  .view-catalog-db-index .default-img .search-wrapper .find-img, .product-images.default-img .search-wrapper .find-img {
    width: 60%;
  }
}
/* line 3372, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .find-img i, .product-images.default-img .search-wrapper .find-img i {
  color: #d3d3d35e;
  position: absolute;
  font-size: 70px;
  top: 5px;
  left: 13px;
  color: white;
}
/* line 3382, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .no-img, .product-images.default-img .search-wrapper .no-img {
  color: #6699FF;
  font-weight: normal;
}
/* line 3386, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .find-img, .product-images.default-img .search-wrapper .find-img {
  background: #6699FF;
  color: #ffffffc2;
  font-weight: normal;
}
/* line 3390, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .find-img i, .product-images.default-img .search-wrapper .find-img i {
  color: #ffffff14;
}
/* line 3392, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper .find-img i.fa-camera, .product-images.default-img .search-wrapper .find-img i.fa-camera {
  color: #ffffffbd;
  font-size: 14px;
  bottom: 4px;
  top: inherit;
  right: 5px;
  left: inherit;
}
/* line 3405, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper:hover .no-img, .product-images.default-img .search-wrapper:hover .no-img {
  border-color: #6699FF;
}
/* line 3408, ../assets/sass/style_custom.scss */
.view-catalog-db-index .default-img .search-wrapper:hover .find-img, .product-images.default-img .search-wrapper:hover .find-img {
  color: white;
}

/*
.search-images {
    .teaser & {
        position: absolute;
        z-index: 5;
    }
    .page-node & {
        margin-bottom: 30px;
    }
    right: 10px;
    color: lightgray;
    cursor: help;
    font-size: 20px;
    &:hover {
        color: $color_C;
    }
}
*/
/* line 3436, ../assets/sass/style_custom.scss */
.product-images.default-img img {
  margin-bottom: inherit;
}
/* line 3441, ../assets/sass/style_custom.scss */
.page-node .product-images.default-img .search-images {
  width: 100%;
  position: absolute;
  top: 30px;
}
/* line 3447, ../assets/sass/style_custom.scss */
.page-node .product-images.default-img .search-images .search-wrapper {
  max-width: 200px;
  margin: -20px auto 60px;
  position: relative;
}
/* line 3452, ../assets/sass/style_custom.scss */
.page-node .product-images.default-img .search-images .search-wrapper .find-img {
  max-width: 100px;
}

/* line 3461, ../assets/sass/style_custom.scss */
.found-images-descr {
  font-size: 10px;
  text-align: center;
  margin-bottom: 15px;
}

/* line 3466, ../assets/sass/style_custom.scss */
div[aria-describedby="zp-found-images-popup"] button {
  background: #6699FF;
  border: none;
  color: white;
  outline: none;
}
/* line 3471, ../assets/sass/style_custom.scss */
div[aria-describedby="zp-found-images-popup"] button:hover {
  background: #2C5FC7;
}

/* line 3479, ../assets/sass/style_custom.scss */
.view-zp-articles-and-brands-db-index li.pager-next {
  margin-left: 5px;
}
/* line 3482, ../assets/sass/style_custom.scss */
.view-zp-articles-and-brands-db-index li.pager-previous {
  margin-right: 5px;
}

/* line 3489, ../assets/sass/style_custom.scss */
.select2-results__option {
  padding: 0;
}
/* line 3491, ../assets/sass/style_custom.scss */
.select2-results__option.select2-results__message, .select2-results__option.loading-results {
  padding-left: 5px;
}
/* line 3495, ../assets/sass/style_custom.scss */
.select2-results__option a {
  text-decoration: none;
}
/* line 3497, ../assets/sass/style_custom.scss */
.select2-results__option a .select2-result-repository__meta {
  padding: 6px;
}
/* line 3503, ../assets/sass/style_custom.scss */
.select2-results__option.select2-results__option--highlighted a {
  color: white;
}

/* line 3510, ../assets/sass/style_custom.scss */
fieldset.panel-default {
  border: none;
}
/* line 3512, ../assets/sass/style_custom.scss */
fieldset.panel-default legend {
  margin: 0;
}
/* line 3514, ../assets/sass/style_custom.scss */
fieldset.panel-default legend a, fieldset.panel-default legend span {
  color: gray;
  float: left;
}
/* line 3519, ../assets/sass/style_custom.scss */
fieldset.panel-default .panel-body {
  border: 1px solid lightgray;
}
/* line 3523, ../assets/sass/style_custom.scss */
fieldset.panel-default i {
  float: left;
  margin-right: 10px;
  color: lightgray;
}
/* line 3527, ../assets/sass/style_custom.scss */
fieldset.panel-default i.closed {
  display: none;
}
/* line 3530, ../assets/sass/style_custom.scss */
fieldset.panel-default i.opened {
  display: block;
}
/* line 3536, ../assets/sass/style_custom.scss */
fieldset.panel-default.collapsible i.closed {
  display: none;
}
/* line 3539, ../assets/sass/style_custom.scss */
fieldset.panel-default.collapsible i.opened {
  display: block;
}
/* line 3544, ../assets/sass/style_custom.scss */
fieldset.panel-default.collapsible.collapsed i.closed {
  display: block;
}
/* line 3547, ../assets/sass/style_custom.scss */
fieldset.panel-default.collapsible.collapsed i.opened {
  display: none;
}

/* line 3556, ../assets/sass/style_custom.scss */
#block-boost-status {
  margin: auto;
  max-width: 400px;
  color: gray;
}

/* line 3564, ../assets/sass/style_custom.scss */
#zp-sort-products {
  margin: 15px 0 20px;
  color: gray;
}
/* line 3567, ../assets/sass/style_custom.scss */
#zp-sort-products .title {
  float: left;
  margin-right: 5px;
}

/* line 3573, ../assets/sass/style_custom.scss */
.start-shopping {
  padding: 10px 10px 10px 7px;
  color: white;
  background: #e23d82;
}
/* line 3578, ../assets/sass/style_custom.scss */
.start-shopping.z01 {
  background: #02884d;
}
/* line 3581, ../assets/sass/style_custom.scss */
.start-shopping.z02 {
  background: #b4d200;
}
/* line 3584, ../assets/sass/style_custom.scss */
.start-shopping.z03 {
  background: #f30145;
}
/* line 3588, ../assets/sass/style_custom.scss */
.start-shopping i {
  font-size: 25px;
  float: left;
  margin-top: 2px;
}
/* line 3593, ../assets/sass/style_custom.scss */
.start-shopping .text {
  margin-left: 37px;
  font-size: 15px;
}
/* line 3597, ../assets/sass/style_custom.scss */
.start-shopping .text .title {
  font-size: 21px;
}
/* line 3603, ../assets/sass/style_custom.scss */
.start-shopping .text .mobile, .start-shopping .text .desktop {
  float: left;
  margin-right: 5px;
}
@media (max-width: 992px) {
  /* line 3608, ../assets/sass/style_custom.scss */
  .start-shopping .text .mobile {
    display: block;
  }
  /* line 3611, ../assets/sass/style_custom.scss */
  .start-shopping .text .desktop {
    display: none;
  }
}
@media (min-width: 992px) {
  /* line 3616, ../assets/sass/style_custom.scss */
  .start-shopping .text .mobile {
    display: none;
  }
  /* line 3619, ../assets/sass/style_custom.scss */
  .start-shopping .text .desktop {
    display: block;
  }
}

/* line 3630, ../assets/sass/style_custom.scss */
.help-block.password-help {
  display: none;
}

/* line 3633, ../assets/sass/style_custom.scss */
.form-type-password .label {
  float: right;
  margin: 2px 0 0 10px;
}

/* line 3640, ../assets/sass/style_custom.scss */
.temp-loading {
  display: none;
}
/* line 3642, ../assets/sass/style_custom.scss */
.loading .temp-loading {
  display: block;
}

/* line 3646, ../assets/sass/style_custom.scss */
.front .slider {
  max-width: 410px;
  margin: 10px auto 0;
  width: 100%;
}
/* line 3650, ../assets/sass/style_custom.scss */
.front .slider .flexslider {
  background: transparent;
  border: none;
  margin: 0;
}
/* line 3655, ../assets/sass/style_custom.scss */
.front .slider .flexslider img {
  margin-top: 0 !important;
  max-width: 400px !important;
  width: 98% !important;
}
/* line 3661, ../assets/sass/style_custom.scss */
.front .slider .flexslider .flex-prev {
  display: none;
}
/* line 3664, ../assets/sass/style_custom.scss */
.front .slider .flexslider .flex-direction-nav a.flex-next:before {
  font-size: 30px;
  color: lightgray;
}

/* line 3674, ../assets/sass/style_custom.scss */
p.added-article-image {
  text-align: center;
}
/* line 3677, ../assets/sass/style_custom.scss */
p.added-article-image.first img, p.added-article-image.third img {
  float: right;
  margin: 10px 0 10px 10px;
}
/* line 3683, ../assets/sass/style_custom.scss */
p.added-article-image.first:last-child img, p.added-article-image.third:last-child img {
  float: none;
}
/* line 3687, ../assets/sass/style_custom.scss */
p.added-article-image.second img {
  float: left;
  margin: 10px 10px 10px 0;
}
/* line 3691, ../assets/sass/style_custom.scss */
p.added-article-image img {
  max-height: 700px;
  max-width: 300px;
}
@media (max-width: 460px) {
  /* line 3697, ../assets/sass/style_custom.scss */
  p.added-article-image.first img, p.added-article-image.second img, p.added-article-image.third img {
    float: none;
    max-width: 200px;
    max-height: 400px;
  }
}

/*# sourceMappingURL=style_custom.css.map */
